
/**
 * Animated Screensaver
 */
function tilezoomScreensaver() {

	this.showErrors = true;
	this.isRunning = false;
	this.savedCoordinates = {};

	// pictures
	this.pictures = [];
	this.pictureLimit = 30;
	this.maxPictureLimit = 210;
	//this.pictureLimit = 5;
	//this.maxPictureLimit = 10;
	this.picturePage = 1;
	this.pictureZindex = 2;

	// do the animation
	this.animate = true; // do the animation
	this.showTiming = true; // timimg, must be on
	this.events = {};
	this.ts_ids = {};
	this.rotatePictures = null; // rotate picutures?
	this.dur_picScaleBig = 750; // picScaleBig duration (1500)
	this.to_picStay = 2500; // timeout picture stays on the wall
	this.dur_picScaleSmall = 750; // _userPicToTilezoomPosition duration
	this.frameRate = 60; // standard browser framerate to calculate how many pictures at once are visible
	this.picturesAtOnce = 3; // number of pictures simultaneously visible in screensaver mode (frameRate*picturesAtOnce)
	this.ease_picScaleBig = 'cubic-bezier(.33,.06,.48,.8)'; // 'cubic-bezier(.31,.08,.9,.42)',
	this.ease_picScaleSmall = 'cubic-bezier(.33,.06,.48,.8)';

	// leave picture on screen or remove every picture (call picToTilezoomPosition)
	this.removePictures = true;

	// screensaver: go fullscreen, hide all, but the navigation and stop every other interaction
	// adHoc: do the animation without hiding or stopping anything
	this.mode = 'adHoc';

	/************************** INITIALIZATION **************************/

	/**
	 * Set tilezoom settings
	 * @param $cont
	 */
	this.init = function($cont) {
		this.tilezoom = $cont;
		this.tilezoomSettings = $cont.data('tilezoom.settings');
		this.$zoomTilesContainer = $('.zoom-tiles');

		// if we use the parent container (zoom-container) to diesplay the images
		// the position needs to be relative
		this.$container = $('.zoom-container');
		this.relativePosition = true;

		//this.testCoordinates();
		//this.initInActivityEvent();
		//this.test();
	};

	this.initInActivityEvent = function() {
		var me = this;

		setTimeout(function() {
			me.resetInactivityEvent();
		},5000);

	};

	this.test = function() {
		var me = this;

		setTimeout(function() {
			me.startScreensaver('adHoc','test');
		}, 4000);
	};

	/************************** Start/Stop FUNCTIONS **************************/

	this.allowScreensaver = function() {

		var route = UTIL.storage.getLocal('visibleContent');

		if(route == 'tilezoommap') {
			return true;
		}

		return false;
	};

	this.allowScreensaverAnimation = function() {

		var route = UTIL.storage.getLocal('visibleContent');
		var controller = this.tilezoomSettings.controller;
		var screensaver = controller.get('screensaver');
		var r = this.allowScreensaver();

		if(this.tilezoomSettings.tilephoto && this.tilezoomSettings.tilephoto.isVisible) {
			return false;
		}

		if(!screensaver) {
			return false;
		}

		var modal = $('.ember-view.wow-modal');
		if(modal && modal.get(0)) {
			return false;
		}

		return r;
	};

	this.getViewport = function() {
		this.holderSize      = this.getHolderSize(this.tilezoom);
		this.containerSize   = this.getContainerSize(this.tilezoom);
		this.tilezoomSettings.util.getViewportCoordinates(this);
	};

	this.updateViewportSize = function(resetPictures,fn,runningAnimationId) {
		resetPictures = resetPictures || true;

		this.getViewport();

		if(resetPictures || (!this.pictures || this.pictures.length < 4)) {
			this.getPictures(resetPictures,fn,runningAnimationId);
		}
	};

	this.startScreensaver = function(mode,e) {
		var me = this;

		if(this.isRunning) {
			return true;
		}

		if(!this.allowScreensaverAnimation()) {
			return false;
		}

		//this.initEvents();
		var isExpanded = this.tilezoomSettings.util.getToggle();
		this.mode = isExpanded ? 'adHoc' : 'screensaver';
		this.tilezoomSettings.controller.set('screensaver',this.mode);

		if(this.mode === 'screensaver') {
			//this.tilezoomSettings.interaction = false;
			//this.tilezoomSettings.controller.set('zoomNavigation',false);
		}

		me.isRunning = true;
		this.runningAnimationId = 'screensaverAniId'+UTIL.getId();
		this.picturePage = 1;
		me.resetInactivityEvent();
		this.updateViewportSize(true,function() {
			me.startAnimation(me.runningAnimationId);
		},me.runningAnimationId);

	};

	this.stopScreensaver = function(e,callback) {
		//if(typeof console === 'object') { console.log('stopScreensaver e,this.isRunning,callback',e,this.isRunning,callback); }
		var me = this;

		if(!this.isRunning) {
			return me.resetInactivityEvent();
		}
		me.isRunning = false;

		//this.tilezoomSettings.interaction = true;

		if(this.mode === 'screensaver') {
			//this.tilezoomSettings.controller.set('zoomNavigation',true);
		}

		me.stopAnimation();
		me.resetInactivityEvent();
		this.pictures = [];

		//if(me.mode === 'screensaver') { // callback should run after toogleContent in this case
		//	me.tilezoomSettings.util.toogleContent(false);
		//	this.mode = 'adHoc';
		//}

		if(typeof callback === 'function') {
			callback();
		}

		return true;
	};

	this.setMode = function(mode) {

		var me = this;

		if(mode === this.mode) {
			return true;
		}

		this.mode = mode;

		switch(this.mode) {
			case'screensaver':
				//me.isRunning = false;
				//me.stopAnimation();
				//this.pictures = [];
				this.savedCoordinates = $.extend(true,{},this.tilezoomSettings.transform);
				this.getViewport();
				var toggled = me.tilezoomSettings.util.toogleContent(true);
				if(toggled === false) {
					this.tilezoomSettings.adjustZoomLevel = true;
				}
				if(toggled !== null) {
					this.stopScreensaver();
				}
				//this.tilezoomSettings.controller.set('zoomNavigation',false);
				break;
			default:
				me.tilezoomSettings.util.toogleContent(false);
				//this.tilezoomSettings.controller.set('zoomNavigation',true);
		}

	};

	this.startAnimation = function(runningAnimationId) {

		var me = this;

		if(!this.isRunning) {
			return false;
		}

		if(typeof this.pictures === 'object' && this.pictures.length > 0) {
			var picture = this.pictures[0];
			if(me.showTiming) picture.starttime = new Date().getTime();
			this.pictures.splice(0,1);
			//this.loadImage(picture,'joyOut');

			me.tilezoomSettings.util.loadImage(me,picture.id,function(a,b,c,pic,img) {
				//if(typeof console === 'object') { console.log('HELLO PIC!',a,b,c,pic,img); }
				if(me.isRunning) {
					me.joyOut(img,pic,runningAnimationId);
				}
			},picture);

			if(!this.pictures) {
				this.getPictures(true,function() {
					me.startAnimation(runningAnimationId);
				},runningAnimationId);
			} else if(this.pictures.length < 10) {
				this.getPictures(false,null,runningAnimationId);
			}
		}
	};


	this.stopAnimation = function() {
		//if(typeof console === 'object') { console.log('this.ts_ids[this.ts_ids.length]',this.ts_ids); }
		if(typeof this.ts_ids === 'object') {
			for(var i in this.ts_ids) {
				clearTimeout(this.ts_ids[i]);
				delete(this.ts_ids[i]);
			}
		}
		this.removeAllPictures();
	};

	/************************** Events **************************/


	/**
	 * Start Screensaver after inactivity of 5secs, if:
	 * - it is not running already
	 * - the user not clicked
	 * - tilephoto is not visible
	 * - no overlay (eg. settings) is visible
	 * Start Screensaver mode "screensaver" after 30secs of inactivity, if
	 * - screensaver is running
	 */
	this.inactivityEventID = null;
	this.inactivityEventTime = null;
	this.inactivityEvent = function() {

		var me = this;


		if(!this.inactivityEventTime) {
			var date = new Date();
			this.inactivityEventTime = date.getTime();
		}

		this.inactivityEventID = setTimeout(function() {
			var now = new Date().getTime();

			//if(typeof console === 'object') { console.log('now,me.inactivityEventTime',now,me.inactivityEventTime,(now-me.inactivityEventTime)); }

			if(me.isRunning) {

				if((now-me.inactivityEventTime) >= 30000) {
					//if(typeof console === 'object') { console.log('INACTIVITY setMode screensaver',true); }
					return me.setMode('screensaver');
				}

			} else {

				if(!me.allowScreensaverAnimation()) {
					return me.resetInactivityEvent();
				}

				if((now-me.inactivityEventTime) >= 10000) {
					//if(typeof console === 'object') { console.log('INACTIVITY startScreensaver',true); }
					return me.startScreensaver('adHoc','inactivity');
				}

			}

			me.inactivityEvent();

		},5000);

	};

	/**
	 * clear inactivityEvent timeout and start it again...
	 */
	this.resetInactivityEvent = function() {

		//if(typeof console === 'object') { console.log('resetInactivityEvent',this.inactivityEventID); }

		if(this.inactivityEventID) {
			clearTimeout(this.inactivityEventID);
		}

		this.inactivityEventTime = null;
		if(this.allowScreensaver()) {
			this.inactivityEvent();
		}
	};



	/************************** Tilezoom FUNCTIONS **************************/

	this.getHolderSize = function($cont) {
		return this.tilezoomSettings.cont.tilezoom('getHolderSize', $cont);
	};

	this.getContainerSize = function($cont) {
		return this.tilezoomSettings.cont.tilezoom('getContainerSize', $cont);
	};

	this.getScale = function(level) {
		return this.tilezoomSettings.cont.tilezoom('getScale', level);
	};

	/************************** Picture FUNCTIONS **************************/


	this.moveToNextWonder = function() {

		var wonder = this.tilezoomSettings.controller.getActiveWonder();

		if(typeof wonder === 'object') {
			wonder = this.tilezoomSettings.controller.getNextWonder(wonder.id);
		}

		if(typeof wonder === 'object') {
			this.tilezoomSettings.controller.gotoWonder(wonder.id);
		} else {
			this.getPictures();
		}
	};

	this.getPictures = function(resetPictures,fn,runningAnimationId) {

		var me = this;

		var h = '';//'http://lp.dev.wallofwonders.com';

		if(this.pictureLimit * this.picturePage >= this.maxPictureLimit) {
			this.picturePage = 1;
			if(this.mode == 'screensaver') {
				return this.moveToNextWonder();
			}
		}

		var l_url = h + '/viewport-pics/'+this.tilezoomSettings.currentTarget+'/'+this.viewport.x+'/'+this.viewport.y+'/'+this.viewport.width+'/'+this.viewport.height+'/'+this.pictureLimit+'/'+this.picturePage;
		$.get(l_url, {}).then(function(data,o) {

			if(runningAnimationId !== me.runningAnimationId) {
				return false;
			}

			if(typeof data === 'object' && typeof data.picture === 'object') {
				for(var i = 0; i < data.picture.length; i++) {
					me.pictures.push(data.picture[i]);
				}
				me.picturePage++;
				if(typeof fn === 'function') {
					fn();
				}
			}

			//if(typeof console === 'object') { console.log('FOUND PICTURES',data,me.pictures); }
		}, function(a) {
			if(typeof console === 'object') { console.log('ERROR ! PICTURES?',a); }
		}.bind(this));

	};

	/************************** HELPER **************************/

	/************************** LOAD PICTURE **************************/



	/************************** SET USER PICTURE **************************/


	this.buildUserPic = function(img,picture) { // deprecated
		var me = this;
		var pic = $('#'+picture.id);

		if(!pic || (!pic.get(0))) {
			fastdom.write(function() {
				me.$container.append('<div id="'+picture.id+'" class="zoom-picture sb cover">');
			});
			pic = $('#'+picture.id);
		}

		picture.$pic = pic;
		return pic;
	};


	this.removeAllPictures = function() {
		var pictures = $('.zoom-picture');
		if(pictures) {
			pictures.remove();
		}
	};


	/************************** BUILD / REMOVE DOM **************************/

	this.randomAngle = 1;
	this.joyOut = function(img,picture,runningAnimationId) {

		if(!this.isRunning) {
			return false;
		}

		if(img && img.src) {
			picture.src = img.src;
		} else {
			return false;
		}

		var me = this;
		if(me.showTiming) picture.loadedtime = new Date().getTime();

		var width = this.tilezoomSettings.util.getPictureSize(this);

		picture.size = {
			width: width,
			height: width
		};

		if(this.isRunning) {
			me.setUserPicture(img,picture,runningAnimationId);
		}

	};

	this.setUserPicture = function(img,picture,runningAnimationId) {

		//if(typeof console === 'object') { console.log('setUserPicture img,picture,this.pictures',img,picture,this.pictures); }
		try {
			var me = this;
			picture = me.tilezoomSettings.util.getPictureXY(picture,picture.metadata.pos,{relative:this.relativePosition});
			var scale =  picture.tilePicDimension.width / picture.size.width;

			fastdom.write(function() {
				me.$container.append('<div id="'+picture.id+'" class="zoom-picture sb cover">');
				fastdom.read(function() {
					picture.$pic = $('#'+picture.id);

					var complete = function(p,r,a) {
						var rotationTimes = UTIL.getRandomInt(2,4);
						//if(typeof console === 'object') { console.log('ROTATE r,(730+r),(730+r)*-1',r,((360*rotationTimes)+r),((360*rotationTimes)+r)*-1); }
						r = r * a;
						me._userPicScaleBig((((360*rotationTimes)+r)* a),a,img,p,runningAnimationId);
						//me.tilezoomSettings.interaction = true;
					};

					me.tilezoomSettings.util.placePictureOnTile(picture,{scale:scale,rotate:me.rotatePictures},complete);
				});
			});


		} catch(e) {
			//this.tilezoomSettings.interaction = true;
			if(typeof console === 'object' && this.showErrors) { console.log('SCREENSAVER.Picture.Error',e); }
		}
	};


	this.get_userPicScaleBig_Position = function(picture) {

		var me = this,
			x,y,offset;

		if(this.relativePosition) {

			x = (picture.tilePicDimension.left - (picture.size.width/2));
			y = (picture.tilePicDimension.top - (picture.size.width/2));
			offset = picture.size.width / 20;

			// check boundaries
			if((x+offset) < 0) {
				x = offset * -1;
			}
			if((y+offset) < 0) {
				y = offset*-1;
			}

			if((x + picture.size.width) > (me.tilezoomSettings.containerWidth + offset)) {
				x = me.tilezoomSettings.containerWidth - picture.size.width + offset;
			}

			if((y + picture.size.width) > (me.tilezoomSettings.containerHeight + offset)) {
				y = me.tilezoomSettings.containerHeight - picture.size.width + offset;
			}

		} else {

			x = (picture.tilePicDimension.left - (picture.tilePicDimension.width/2));
			y = (picture.tilePicDimension.top - (picture.tilePicDimension.width/2));
			offset = picture.size.width / 10;

			// check boundaries
			if((x+offset) < this.scaledViewport.x) {
				x = this.scaledViewport.x - offset;
			}
			if((y+offset) < this.scaledViewport.y) {
				y = this.scaledViewport.y - offset;
			}
			if((x + picture.size.width) > (this.scaledViewport.x + this.scaledViewport.width + offset)) {
				x = this.scaledViewport.x + this.scaledViewport.width - picture.size.width + offset;
			}

			if((y + picture.size.width) > (this.scaledViewport.y + this.scaledViewport.height + offset)) {
				y = this.scaledViewport.y + this.scaledViewport.height - picture.size.width + offset;
			}
		}

		return {
			x:x,
			y:y
		}
	};

	this._userPicScaleBig = function(r,a,img,picture,runningAnimationId) {
		if(!this.isRunning) {
			return false;
		}

		var me = this;
		if(me.showTiming) picture._userPicScaleBigStarttime = new Date().getTime();

		try {
			var scale = 1;

			r = (r*-1);
			var xy = this.get_userPicScaleBig_Position(picture);
			var x = xy.x;
			var y = xy.y;

			//if(typeof console === 'object') { console.log('_userPicScaleBig x,y,this.scaledViewport,picture.size',x,y,this.scaledViewport,picture.size,picture.tilePicDimension); }
			//if(typeof console === 'object') { console.log('_userPicScaleBig rotate',r); }
			fastdom.write(function() {
				picture.$pic.transition({
					scale: scale,
					rotate: me.rotatePictures ? r + 'deg' : 0,
					x: x+'px',
					y: y+'px',
					complete: function() {
						picture.$pic.on('click',function() {
							me.tilezoomSettings.tilephoto.getPhoto({
								custom: true,
								coordinates: {
									x: picture.metadata.pos[0],
									y: picture.metadata.pos[1]
								}
							});
						});
						if(runningAnimationId !== me.runningAnimationId) {
							return false;
						}
						var ts_ids_idx = UTIL.getId();
						me.ts_ids[ts_ids_idx] = setTimeout(function() {
							if(me.showTiming) picture._userPicScaledBigEndtime = new Date().getTime();
							if(me.animate && me.isRunning) {
								me.startAnimation(runningAnimationId);
							}
							delete(me.ts_ids[ts_ids_idx]);
							if(me.removePictures) {
								var frames = me.frameRate;
								if(me.mode === 'screensaver') {
									frames = parseInt((((picture._userPicScaledBigEndtime-picture.starttime) / 1000) * (me.frameRate)) * me.picturesAtOnce);
								}

								fastdom.defer(frames,function () {
									var backRotation = (720*a)+'deg';
									me.tilezoomSettings.util._userPicToTilezoomPosition(picture,{easing: me.ease_picScaleSmall,remove:true,duration:me.dur_picScaleSmall,rotate:backRotation},null);
								});
							}
						},me.to_picStay);


					},
					//easing: 'cubic-bezier(0,0.9,0.3,1)',
					//easing: 'cubic-bezier(.49,.04,.92,.1)',
					easing: me.ease_picScaleBig,
					//easing: 'ease-in',
					duration: me.dur_picScaleBig
				});
			});

		} catch(e) {
			//this.tilezoomSettings.interaction = true;
			if(typeof console === 'object' && this.showErrors) { console.log('SCREENSAVER._userPicScaleBig.Error',e); }
		}

	};

	/************************** ANIMATE **************************/

	/************************** CALLBACKS AFTER LAODING THE USER PICTURE: JJOY AND JOY PLACEMENT **************************/

	/************************** JOY IN ANIMATIONS **************************/

	/************************** TEST x0 and y0 coordinates **************************/


	this.testCoordinates = function() {
		//return true;
		var me = this;

		this.getXY();
		this.$zoomTilesContainer.append('<div class="trcenter">');
		$('.trcenter').css({
			width: '10px',
			height: '10px',
			left: this.x0+'px',
			top: this.y0+'px',
			backgroundColor: 'red',
			position: 'absolute',
			zIndex: 10
		});

		$('body').append('<div class="trtestW"></div>');
		$('.trtestW').css({
			padding: '10px',
			//width: '100px',
			//height: '100px',
			left: '10px',
			top: '10px',
			backgroundColor: 'rgba(255,255,255,0.65)',
			position: 'absolute',
			zIndex: 11000
		});

		//$('.trtestW').append('<a class="trtestxy">XY</a>');
		//$('.trtestxy').on('click',function() {
		//	me.getXY();
		//});

		//$('.trtestW').append('| <a class="trtestpics">get pics</a>');
		//$('.trtestpics').on('click',function() {
		//	me.getPictures();
		//});
		//
		$('.trtestW').append('| <a class="trtestani">next</a>');
		$('.trtestani').on('click',function() {
			me.animate = false;
			me.removePictures = false;
			me.startAnimation();
		});
		//
		//$('.trtestW').append('| <a class="trtestremove">removeAllPictures</a>');
		//$('.trtestremove').on('click',function() {
		//	me.removeAllPictures();
		//});


		$('.trtestW').append('| <a class="trteststart">start</a>');
		$('.trteststart').on('click',function() {
			me.animate = true;
			me.removePictures = true;
			me.startScreensaver('adHoc','Start');
		});
		$('.trtestW').append('| <a class="trteststop">stop</a>');
		$('.trteststop').on('click',function(e) {
			me.animate = false;
			me.stopScreensaver(e);
		});
		$('.trtestW').append('| <a class="trtestsetMode">setMode</a>');
		$('.trtestsetMode').on('click',function() {
			me.setMode('screensaver');
		});

	};


	/************************** DEPRECATED **************************/

	this._userPicToTilezoomPosition = function(r,scale,img,picture) { // deprecated use tilezoomSettings.util

		if(!this.isRunning) {
			return false;
		}

		var me = this;
		if(me.showTiming) picture._userPicToTilezoomPositionStarttime = new Date().getTime();

		try {

			fastdom.write(function() {
				picture.$pic.transition({
					scale: picture.tilePicDimension.width/ picture.size.width,
					rotate: 1,//(r < 0) ? '720deg' : '-720deg',
					x: picture.tilePicDimension.left+'px',
					y: picture.tilePicDimension.top+'px',
					complete: function() {
						//me.tilezoomSettings.interaction = true;
						fastdom.write(function() {
							picture.removeUserPictureStarttime = new Date().getTime();
							if(me.showTiming) {
								//if(typeof console === 'object') { console.log('starttime,loadedtime,_userPicRotateStarttime,_userPicScaleBigStarttime,_userPicToTilezoomPositionStarttime,removeUserPictureStarttime',picture.starttime,picture.loadedtime,picture._userPicRotateStarttime,picture._userPicScaleBigStarttime,picture._userPicToTilezoomPositionStarttime,picture.removeUserPictureStarttime); }
								//if(typeof console === 'object') { console.log('starttime till _userPicScaledBigEndtime',(picture._userPicScaledBigEndtime-picture.starttime)); }
							}
							me.removeUserPicture(img,picture);
						});
					},
					duration: 400,
					easing: 'cubic-bezier(0,0.9,0.3,1)'
				});
			});


		} catch(e) {
			//this.tilezoomSettings.interaction = true;
			if(typeof console === 'object' && this.showErrors) { console.log('SCREENSAVER._userPicScaleBig.Error',e); }
		}
	};


	this._userPicRotate = function(r,a,img,picture,c) { // not needed
		if(!this.isRunning) {
			return false;
		}

		var me = this;
		if(me.showTiming) picture._userPicRotateStarttime = new Date().getTime();

		try {
			fastdom.write(function() {
				picture.$pic.transition({
					rotate: r+'deg',
					complete: function() {
						fastdom.defer(1,function () {
							me._userPicScaleBig(r,a,img,picture);
						});
						//setTimeout(function() {me._userPicScaleBig(r,a,img,picture)},1000);
					},
					duration: 300
				});
			});

		} catch(e) {
			//this.tilezoomSettings.interaction = true;
			if(typeof console === 'object' && this.showErrors) { console.log('SCREENSAVER._userPicRotate.Error',e); }
		}

	};



	/**
	 * load the user picture
	 *
	 * @param picture
	 * @param callback
	 */
	this.loadImage = function(picture,callback) {

		var img = new Image(),
			me = this;

		if(!this.isRunning) {
			return false;
		}
		//if(typeof console === 'object') { console.log('loadImage',picture,callback); }

		img.onload = function(){
			if(typeof callback === 'function') {
				defer(callback,100,me,[this,picture]);
			} else {
				me[callback](this,picture);
			}

		};

		img.onerror = function(){
			//me.tilezoomSettings.interaction = true;
			if(typeof callback === 'function') {
				defer(callback,100,me,[false,picture]);
			} else {
				me[callback](this,picture);
			}
		};

		if(picture && picture.id) {
			//if(typeof console === 'object') { console.log('LOAD PIC?!',this.getImageSrc(picture)); }
			img.src = this.getImageSrc(picture);
		}
	};

	this.getImageSrc = function(picture) {

		//if(typeof console === 'object') { console.log('srvParams',picture,srvParams); }

		var wowmosaicFrontendUrlBase = srvParams.wowmosaicFrontendUrlBase;
		var path = wowmosaicFrontendUrlBase+ '/cropped';
		if((!picture.metadata.cropX) || (!picture.metadata.cropY)) {
			return path+'/'+picture.id;
		}
		return path+'/'+picture.id + '/' + picture.metadata.cropX + '-' + picture.metadata.cropY;


	};


	this.removeUserPicture = function(img,picture) { // deprecated use tilezoomSettings.util.

		if(picture.$pic) {
			picture.$pic.remove();
		}
	};



	/**
	 * get coordinates
	 */
	this.getXY = function() {

		this.x0 = (this.tilezoomSettings.transform.x < 0) ? (this.tilezoomSettings.transform.x*-1) : 0;
		this.y0 = (this.tilezoomSettings.transform.y < 0) ? (this.tilezoomSettings.transform.y*-1) : 0;
		this.width = (this.tilezoomSettings.containerWidth);
		this.height = (this.tilezoomSettings.containerHeight);

		this.scale =  this.getScale (this.tilezoomSettings.level);

		this.viewport = {
			x:	parseInt(Math.ceil( this.x0  / this.scale )),
			y:	parseInt(Math.ceil( this.y0 / this.scale )),
			width:	parseInt(Math.ceil( this.width  / this.scale )),
			height:	parseInt(Math.ceil( this.height / this.scale ))
		};

		this.scaledViewport = {
			x:	this.x0,
			y:	this.y0,
			width:	this.width,
			height:	this.height
		};

		//if(typeof console === 'object') { console.log('this.x0, this.y0,this.width,this.height,this.viewport',this.x0, this.y0,this.width,this.height,this.viewport); }
	};
































	/** DEPRECATED **/


	this.stopEvents = function(eventSelectors,fn) {

		var isTouchSupported = (typeof(window.ontouchstart) != 'undefined'),
			i = 0;

		if(isTouchSupported) {
			for(i=0; i < eventSelectors.length;i++) {
				if(typeof console === 'object') { console.log('ev',ev); }
				if(this.events[ev]) {
					this.events[ev].off('tap',fn);
				}
			}
		} else {
			for(i=0; i < eventSelectors.length;i++) {
				$('.'+eventSelectors[i]).off('mousedown', fn);
			}
		}

	};

	this.initEvents = function() {

		//return true;

		var me = this,
			i=0;
		var isTouchSupported = (typeof(window.ontouchstart) != 'undefined');
		var $zoomAction = this.tilezoom.find('.zoom-action');
		var $map = $('.ui-tilezoommap-wrapper');
		var $body = $('body');

		// onresize?
		var mouseDown = function(e) {
			if(typeof console === 'object') { console.log('MOUSEDOWN',e,$(this)); }

			var _this = $(this);
			var n = _this.closest('.zoom-navigation');
			var r = _this.closest('.zoom-navigation').get(0);//(n && n.get(0));

			if(r) {
				return;
			}

			if(me.isRunning) {
				me.stopScreensaver(e);
			}
		};

		// @zoom-action only onmove? mousemeve and pan
		// add stopScreensaver to all other pans, touches and clicks to stop it emideatly ('zoom-action')
		var eventSelectors = ['ui-tilezoommap-wrapper','app-navigation'];

		this.stopEvents(eventSelectors,mouseDown);

		if(isTouchSupported) {
			for(i=0; i < eventSelectors.length;i++) {
				var ev = $('.'+eventSelectors[i]);
				if(!ev && ev.get(0)) continue;
				//if(typeof console === 'object') { console.log('ev',ev); }
				//if(this.events[ev]) {
				//	this.events[ev].off('tap',mouseDown);
				//}
				this.events[eventSelectors[i]] = new Hammer.Manager(ev.get(0));
				this.events[eventSelectors[i]].add(new Hammer.Tap({ event: 'tap'}));
				this.events[eventSelectors[i]].on('tap',mouseDown);
			}
		} else {
			for(i=0; i < eventSelectors.length;i++) {
				$('.'+eventSelectors[i]).on('mousedown', mouseDown);
			}
		}

		// use body event to get inactivity?
	};



}

