/**
 * Utils...
 */

function tilezoomUtil() {

	this.pictureZindex = 4;
	this.randomAngle = 1;

	/************************** init utils **************************/

	this.init = function($cont) {
		this.tilezoom = $cont;
		this.tilezoomSettings = $cont.data('tilezoom.settings');
		this.$zoomTilesContainer = $('.zoom-tiles');
		this.zoomActionContainer = $cont.find('.zoom-action');
	};

	/************************** Tilezoom FUNCTIONS **************************/

	this.getHolderSize = function($cont) {
		return this.tilezoomSettings.cont.tilezoom('getHolderSize', $cont);
	};

	this.getContainerSize = function($cont) {
		return this.tilezoomSettings.cont.tilezoom('getContainerSize', $cont);
	};

	this.getScale = function(level) {
		return this.tilezoomSettings.cont.tilezoom('getScale', level);
	};

	/************************** LOAD PICTURE **************************/

	/**
	 * load the user picture
	 *
	 * @param picture
	 * @param callback
	 */
	this.loadImage = function(me,pictureId,callback) {

		var img = new Image();
		var args = Array.prototype.slice.call(arguments);

		img.onload = function(){
			if(typeof callback === 'function') {
				args.push(this);
				defer(callback,10,me,args);
			}
		};

		img.onerror = function(){
			me.tilezoomSettings.interaction = true;
			if(typeof callback === 'function') {
				args.push(this);
				defer(callback,10,me,args);
			}
		};

		if(pictureId) {
			img.src = this.getImageSrc(pictureId);
		}
	};

	/**
	 * Standard image url...
	 * @param pictureId
	 * @returns {string}
	 */
	this.getImageSrc = function(pictureId) {
		var wowmosaicFrontendUrlBase = srvParams.wowmosaicFrontendUrlBase;
		var path = wowmosaicFrontendUrlBase+ '/cropped';
		return path+'/'+pictureId;
	};

	/************************** PICTURE UTILS **************************/

	/**
	 * Picture size on screen
	 *
	 * @param me
	 * @returns {number}
	 */
	this.getPictureSize = function(me) {

		var width = 200;
		if(width >= (me.containerSize.containerWidth-50)) {
			width = (me.containerSize.containerWidth-50);
		}

		if(width >= (me.containerSize.containerHeight-50)) {
			width = (me.containerSize.containerHeight-50);
		}

		return width;

	};


	/**
	 * get coordinates to place picture on tile position
	 *
	 * @param picture
	 * @param pos
	 * @returns {*}
	 */
	this.getPictureXY = function(picture,pos,o) {

		o = o || {};
		this.scale =  this.getScale (this.tilezoomSettings.level, this.tilezoomSettings);

		picture.tilePicDimension = {
			width:	parseInt(Math.ceil( this.tilezoomSettings.tileWidth * this.scale )),
			height:	parseInt(Math.ceil( this.tilezoomSettings.tileHeight * this.scale )),
			left:   o.relative ? (pos[0] * this.scale) + this.tilezoomSettings.transform.x : pos[0] * this.scale,
			top:    o.relative ? (pos[1] * this.scale) + this.tilezoomSettings.transform.y : pos[1] * this.scale
		};

		//if(typeof console === 'object') { console.log('getPictureXY',pos,this.scale,this.tilezoomSettings.transform.x); }

		return picture;
	};

	/**
	 *
	 * because, if the transform origin is not "0 0"
	 * the image moves on scale,
	 * we need to correct that
	 * it is not possible to change the transformOrigin
	 * because the rotation would than also depending on that transformOrigin
	 * @param picture
	 * @param scale
	 * @returns {{x: number, y: number}}
	 */
	this.correctTransformOrigin = function(picture,scale) {

		var x = picture.tilePicDimension.left - (picture.size.width / 2) - ((picture.size.width * scale) /2) + (picture.size.width * scale);
		var y = picture.tilePicDimension.top - (picture.size.width / 2) - ((picture.size.width * scale) /2) + (picture.size.width * scale);

		return {
			x:x,
			y:y
		}
	};

	this.placePictureOnTile = function(picture, o, callback) {

		var me = this;
		o = o || {};

		fastdom.write(function() {

			var xy = me.correctTransformOrigin(picture,o.scale);
			var x = xy.x;
			var y = xy.y;

			picture.$pic.css({
				visibility: 'hidden',
				width: picture.size.width+'px',
				height: picture.size.height+'px',
				x: x+'px',
				y: y+'px',
				left: '0px',
				top: '0px',
				zIndex: me.pictureZindex,
				scale: o.scale,
				backgroundImage: 'url('+picture.src+')'
			});

			me.pictureZindex++;

			fastdom.write(function() {

				var r = UTIL.getRandomInt(10,30) * me.randomAngle;
				me.randomAngle = me.randomAngle * -1;

				picture.$pic.css({
					visibility: 'visible',
					rotate: o.rotate ? r + 'deg' : null,
					transformOrigin: '50% 50%'
				});

				callback(picture,r,me.randomAngle);

			});
		});
	};



	this._userPicToTilezoomPosition = function(picture,o,callback) {

		o = o || {};
		var _this = this;

		fastdom.write(function() {
			var scale = picture.tilePicDimension.width/ picture.size.width;
			var xy = _this.correctTransformOrigin(picture,scale);
			var x = xy.x;
			var y = xy.y;

			picture.$pic.transition({
				scale: scale,
				rotate: o.rotate ? o.rotate : null,
				x: x || picture.tilePicDimension.left+'px',
				y: y || picture.tilePicDimension.top+'px',
				complete: function() {
					fastdom.write(function() {
						if(o.remove) {
							fastdom.defer(60,function() {
								_this.removeUserPicture(picture);
							});
						}

						if(typeof callback === 'function') {
							callback(picture);
						}

					});
				},
				duration: o.duration || 400,
				easing: o.easing || 'cubic-bezier(.33,.06,.48,.8)'
			});
		});
	};


	this.removeUserPicture = function(picture) {
		if(picture.$pic) {
			picture.$pic.remove();
		}
	};

	/************************** Loader **************************/


	this.getLoadingAnimation = function() {
		return '<div class="loading-animation"><i class="icon-spin4 animate-spin"></i></div>';
	};

	this._placeLoaderOnTile = function(c,o) {

		var me = this;
		o = o || {};

		var $tileLoader = $('.zoom-loader');

		if($tileLoader && $tileLoader.get(0)) {
			me.$tileLoader = $tileLoader;
		} else {
			me.$zoomTilesContainer.append('<div class="zoom-loader"></div>');
			me.$tileLoader = $('.zoom-loader');
		}
		if(typeof console === 'object') { console.log('me.$tileLoader',me.$tileLoader); }
		this.getPictureXY(me.$tileLoader ,[c.x,c.y]);

		if(typeof console === 'object') { console.log('me.$tileLoader',me.$tileLoader); }
		me.$tileLoader.html(this.getLoadingAnimation());
		me.$tileLoader.css({
			//visibility: 'hidden',
			width: me.$tileLoader.tilePicDimension.width+'px',
			height: me.$tileLoader.tilePicDimension.height+'px',
			x: me.$tileLoader.tilePicDimension.left+'px',
			y: me.$tileLoader.tilePicDimension.top+'px',
			left: '0px',
			top: '0px',
			zIndex: me.pictureZindex,
			//transformOrigin: '0px 0px',
			//scale: o.scale
		});

	};

	/************************** Viewport UTILS **************************/

	/**
	 * get coordinates
	 */
	this.getViewportCoordinates = function(me) {
		
		var me = me || this;
		var $zoomContainer = $('.zoom-container');

		me.x0 = (me.tilezoomSettings.transform.x < 0) ? (me.tilezoomSettings.transform.x*-1) : 0;
		me.y0 = (me.tilezoomSettings.transform.y < 0) ? (me.tilezoomSettings.transform.y*-1) : 0;
		me.width = $zoomContainer.width() || (me.tilezoomSettings.containerWidth);
		me.height = $zoomContainer.height() || (me.tilezoomSettings.containerHeight);

		me.scale =  this.getScale (me.tilezoomSettings.level);

		me.viewport = {
			x:	parseInt(Math.ceil( me.x0  / me.scale )),
			y:	parseInt(Math.ceil( me.y0 / me.scale )),
			width:	parseInt(Math.ceil( me.width  / me.scale )),
			height:	parseInt(Math.ceil( me.height / me.scale ))
		};

		me.scaledViewport = {
			x:	me.x0,
			y:	me.y0,
			width:	me.width,
			height:	me.height
		};

		//if(typeof console === 'object') { console.log('this.x0, this.y0,this.width,this.height,this.viewport',me.x0, me.y0,me.width,me.height,me.viewport,me.scaledViewport); }
	};

	/**
	 * get coordinates of clicked tile
	 *
	 * @param e
	 */
	this.getClickCoordinates = function(e) {

		if((!e) || typeof e !== 'object') {
			return false;
		}

		if(e.pointerType == 'touch') {
			e = e.pointers[0];
		} else {
			e = e.pageX ? e : e.originalEvent;
		}

		if((!e) || (!e.pageX)) {
			return false;
		}

		// if(typeof console === 'object') { console.log('CLICKED on TILE getCoordinates',e,this.tilezoomSettings); }

		var startLeft	= this.tilezoomSettings.transform.x * -1,
			startTop	= this.tilezoomSettings.transform.y * -1;

		var startX      = e.pageX,
			startY      = e.pageY;

		this.scale =  this.getScale (this.tilezoomSettings.level, this.tilezoomSettings);

		//if(typeof console === 'object') { console.log('startX,startY,(startLeft + startX),(startTop + startY),this.scale,(startTop + startY) * this.scale',startX,startY,(startLeft + startX),(startTop + startY),this.scale,(startTop + startY) * this.scale); }

		var xy = {
			// scaled factor
			xs: (startLeft + startX),
			ys: (startTop + startY),
			// unscaled factor
			x: (startLeft + startX) / this.scale,
			y: (startTop + startY) / this.scale
		};

		var uxy = this.recheckUserPictureCoordinates(xy,this.scale);
		if(typeof uxy === 'object') {
			xy = uxy;
		}

		// 0/0 tile position
		var tileSize = (this.tilezoomSettings.tileWidth * this.tilezoomSettings.scaleFactor);
		xy.x0 = Math.floor((xy.xs) / tileSize) * tileSize;
		xy.y0 = Math.floor((xy.ys) / tileSize) * tileSize;

		return xy;

	};

	/**
	 * Click on (joy) user picture
	 */
	this.recheckUserPictureCoordinates = function(xy,scale) {

		var userPictureEl = $('.zoom-holder .user-picture');
		var hasUserPictureEl = (userPictureEl && userPictureEl.get(0));
		var r = false;

		if(!hasUserPictureEl) {
			return false;
		}

		var w = userPictureEl.width() +6; // 6px border
		var h = userPictureEl.height() +6;
		var transform = UTIL.getTransformMatrix(userPictureEl);
		w = w * transform.s1;
		h = h * transform.s1;


		var t = (transform.x) + ((w) / 2);
		//if(typeof console === 'object') { console.log('xUSERPICTURE scale,w,h,transform,yx',scale,w,h,transform,xy,t); }

		transform.x1 = (transform.x) + ((w/2)); // transform origin center/center
		transform.y1 = (transform.y) + ((h/2));
		transform.x2 = transform.x1 + (w);
		transform.y2 = transform.y1 + (h);

		//if(typeof console === 'object') { console.log('transform',transform,xy); }

		if(transform.x1 < xy.xs && transform.x2 > xy.xs && transform.y1 < xy.ys && transform.y2 > xy.ys) {
			//if(typeof console === 'object') { console.log('this.tilezoomSettings.joy',this.tilezoomSettings.joy); }
			if(this.tilezoomSettings.joy.photo) {
				//if(typeof console === 'object') { console.log('FOUND USER PICTuRE',this.tilezoomSettings.joy.photo); }
				var pos = this.tilezoomSettings.joy.photo.get('metadata.pos');
				if(pos) {
					r = {
						x: pos[0],
						y: pos[1]
					};
				}
			}
		}


		return r;

	};



	/************************** BaseView (Navigation) Functions **************************/
	/**
	 * Toggle Content, leave Navigation
	 *
	 * @param t
	 */
	this.toogleContent = function(t) {
		var parentView = this.tilezoomSettings.parentView;
		var controller = parentView.get('controller');
		var willBeExpanded = ( controller.get('model.applicationstate.expanded') ? false : true ),
			hasToogled = null;

		if(t && (!willBeExpanded)) {
			this.tilezoomSettings.parentView.send('toggleContent');
			hasToogled = willBeExpanded;
		} else if((!t) && willBeExpanded) {
			this.tilezoomSettings.parentView.send('toggleContent');
			hasToogled = willBeExpanded;
		}

		return hasToogled;
	};

	this.getToggle = function() {
		var parentView = this.tilezoomSettings.parentView;
		var controller = parentView.get('controller');
		return ( controller.get('model.applicationstate.expanded') ? true : false );
	};

	/************************** User Functions **************************/

	this.userIsLoggedIn = function() {
		var controller = this.tilezoomSettings.controller;
		return controller.get('isLoggedIn');
	};


}