define('ember-cli-server-variables/services/server-variables', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ServerVariablesService = Ember['default'].Service.extend({
    unknownProperty: function unknownProperty(serverVar) {
      var ENV = this.get('env');
      var prefix = ENV.serverVariables.tagPrefix || ENV.modulePrefix;
      var dasherizedVar = Ember['default'].String.dasherize(serverVar);

      var content = Ember['default'].$('head meta[name=' + prefix + '-' + dasherizedVar + ']').attr('content');
      if (content !== "") {
        return content;
      } else {
        return undefined;
      }
    }
  });

  exports['default'] = ServerVariablesService;

});