
/**
 * JoyIn, JoyOut, Picture Placement and JoyPlacement
 */
function tilezoomJoy() {

	this.showErrors = true;
	this.lastClickCoodinates = null;

	//this.isVisible = false;

	/************************** INITIALIZATION **************************/

	/**
	 * Set tilezoom settings
	 * @param $cont
	 */
	this.init = function($cont) {
		this.tilezoomSettings = $cont.data('tilezoom.settings');
	};

	/**
	 * set user picture
	 * @param photo
	 */
	this.setPhoto = function(photo) {
		this.photo = photo;
	};
	/************************** Tilezoom FUNCTIONS **************************/

	this.getHolderSize = function($cont) {
		return this.tilezoomSettings.cont.tilezoom('getHolderSize', $cont);

	};
	this.getContainerSize = function($cont) {
		return this.tilezoomSettings.cont.tilezoom('getContainerSize', $cont);
	};

	this.getScale = function(level) {
		return this.tilezoomSettings.cont.tilezoom('getScale', level);

	};
	/************************** START FUNCTIONS **************************/

	/**
	 * Place user picture on top of mouse clicked tile
	 * @param $cont
	 * @returns {boolean}
	 */
	this.doPositionUserPicture = function($cont,e) {

		var me = this,
			borderWidth = 1;

		this.holderSize      = this.getHolderSize($cont);
		this.containerSize   = this.getContainerSize($cont);
		this.$zoomTilesContainer = $('.zoom-tiles');

		if(!this.photo) {
			this.tilezoomSettings.interaction = true;
			return false;
		}

		this.getXY();
		this.buildUserPic();

		var coordinates = me.tilezoomSettings.util.getClickCoordinates(e);
		this.lastClickCoodinates = coordinates;

		this.userPic.css({
			scale: 1,
			borderWidth: borderWidth + 'px',
			borderRadius: borderWidth+'px',
			width: this.tilePicDimension.width+'px',
			height: this.tilePicDimension.height+'px',
			left: '0px',
			top: '0px',
			backgroundImage: 'url('+this.photo.get('croppedSrc')+')'
		});

		this.userPic.transition({
			x: coordinates.x0 +'px',
			y: coordinates.y0 +'px',
			duration: 350
		});
	};

	/**
	 * Place user picture on top of tile
	 * @param $cont
	 * @returns {boolean}
	 */
	this.doSetUserPicture = function($cont) {
		var me = this;
		this.holderSize      = this.getHolderSize($cont);
		this.containerSize   = this.getContainerSize($cont);
		this.$zoomTilesContainer = $('.zoom-tiles');

		if(!this.photo) {
			return false;
		}
		this.getXY();
		this.buildPlaceholder();
		this.loadImage('setUserPicture');
	};

	/**
	 * Do joy screen animation
	 * @param $cont
	 * @returns {boolean}
	 */
	this.doJoyIn = function($cont) {

		var me = this;
		this.holderSize      = this.getHolderSize($cont);
		this.containerSize   = this.getContainerSize($cont);
		this.$zoomTilesContainer = $('.zoom-tiles');

		if(!this.photo) {
			this.tilezoomSettings.interaction = true;
			return false;
		}
		this.getXY();
		//this.test();
		this.buildPlaceholder();
		this.loadImage('joyIn');
	};

	/**
	 * do joy out animation (in my photo)
	 * @param $cont
	 * @returns {boolean}
	 */
	this.doJoyOut = function($cont) {

		var me = this;
		this.holderSize      = this.getHolderSize($cont);
		this.containerSize   = this.getContainerSize($cont);
		this.$zoomTilesContainer = $('.zoom-tiles');

		if(!this.photo) {
			this.tilezoomSettings.interaction = true;
			return false;
		}

		this.getXY();
		this.loadImage('joyOut');
	};

	/**
	 * Just do the end of joy screen animation
	 * place userpicture next to tile wit arrow
	 *
	 * @param $cont
	 * @returns {boolean}
	 */
	this.doJoyPlacement = function($cont) {

		var me = this;
		this.holderSize      = this.getHolderSize($cont);
		this.containerSize   = this.getContainerSize($cont);
		this.$zoomTilesContainer = $('.zoom-tiles');

		if(!this.photo) {
			this.tilezoomSettings.interaction = true;
			return false;
		}

		this.getXY();
		this.loadImage('joyPlacement');
	};

	/************************** HELPER **************************/


	/**
	 * get coordinates
	 */
	this.getXY = function() {

		this.x0 = (this.tilezoomSettings.transform.x*-1);
		this.y0 = (this.tilezoomSettings.transform.y*-1);

		this.scale =  this.getScale (this.tilezoomSettings.level, this.tilezoomSettings);

		this.tilePicDimension = {
			width:	parseInt(Math.ceil( this.tilezoomSettings.tileWidth * this.scale )),
			height:	parseInt(Math.ceil( this.tilezoomSettings.tileHeight * this.scale )),
			left:   this.photo.get('metadata').pos[0] * this.scale,
			top:    this.photo.get('metadata').pos[1] * this.scale
		};
	};

	/**
	 * load the user picture
	 *
	 * @param callback
	 */
	this.loadImage = function(callback) {

		var img = new Image(),
			me = this;

		//if(typeof console === 'object') { console.log('loadImage',this.photo,callback); }

		img.onload = function(){
			if(typeof callback === 'function') {
				defer(callback,100,me,[this]);
			} else {
				me[callback](this);
			}

		};

		img.onerror = function(){
			me.tilezoomSettings.interaction = true;
			if(typeof callback === 'function') {
				defer(callback,100,me,[false]);
			} else {
				me[callback](this);
			}
		};

		if(this.photo && this.photo.get('id')) {
			img.src = this.photo.get('croppedSrc');
		}
	};

	/************************** BUILD / REMOVE DOM **************************/

	/**
	 * place holder on top of tile
	 */
	this.buildPlaceholder = function() {

		if(!this.userPicBorder) {
			this.$zoomTilesContainer.append('<div class="user-picture-border">');
		}
		this.userPicBorder = $('.user-picture-border');
		var borderSize = 3;

		this.userPicBorder.css({
			backgroundColor: '#ffffff',
			width: (this.tilePicDimension.width + (2*borderSize)) + 'px',
			height: (this.tilePicDimension.height + (2*borderSize)) + 'px',
			left: (this.tilePicDimension.left - borderSize) + 'px',
			top: (this.tilePicDimension.top - borderSize) + 'px',
			zIndex: 1,
			position: 'absolute'
		});
	};

	this.removePlaceHolder = function() {

		this.userPicBorder = $('.user-picture-border');
		if(this.userPicBorder) {
			this.userPicBorder.remove();
			delete(this.userPicBorder);
		}

	};

	this.removeUserPicture = function() {

		this.userPic = $('.user-picture');
		if(this.userPic) {
			this.userPic.remove();
			delete(this.userPic);
		}
	};

	this.removeUserPicArrowBorder = function() {

		this.userPicArrowBorder = $('#user-pic-arrow-border');//'.arrow-box-left'
		if(this.userPicArrowBorder) {
			this.userPicArrowBorder.remove();
			delete(this.userPicArrowBorder);
		}
	};

	this.buildUserPic = function() {
		this.userPic = $('.user-picture');

		if(!this.userPic || (!this.userPic.get(0))) {
			this.$zoomTilesContainer.append('<div class="user-picture smile cover">');
			this.userPic = $('.user-picture');
		}
	};

	/************************** CALLBACKS AFTER LAODING THE USER PICTURE: JJOY AND JOY PLACEMENT **************************/

	this.joyIn = function(img) {


		var me = this;


		try {
			this.removePlaceHolder();
			this.removeUserPicture();
			this.removeUserPicArrowBorder();
			this.buildUserPic();


			var cords = this.placeUserPictureOutside(img);

			this.userPic.transition({
				x: cords.center,
				y: (this.y0 - cords.width),
				rotate: '10deg',
				duration: 0
			});

			this.placeOnTop();
		} catch(e) {
			this.tilezoomSettings.interaction = true;
			this.afterJoinIn();
			if(typeof console === 'object' && this.showErrors) { console.log('JOY.joyIn.Error',e); }
		}

		return this.userPic;
	};

	this.joyOut = function(img) {

		var me = this;
		this.removePlaceHolder();
		this.removeUserPicture();
		this.removeUserPicArrowBorder();
		this.setUserPicture(img);

		var width = 200;
		if(width >= (this.containerSize.containerWidth-20)) {
			width = (this.containerSize.containerWidth-20);
		}

		if(width >= (this.containerSize.containerHeight-20)) {
			width = (this.containerSize.containerHeight-20);
		}

		this.userPicSize = {
			width: width,
			height: width
		};

		this._userPicRotate(10,function() {
			me._userPicScaleBig();
		});

		this.tilezoomSettings.interaction = true;

	};

	this.joyPlacement = function(img) {


		var me = this;

		try {
			//if(typeof console === 'object') { console.log('buildUserPic IMG',img); }
			this.removePlaceHolder();
			this.removeUserPicture();
			this.removeUserPicArrowBorder();
			this.buildUserPic();

			var cords = this.placeUserPictureOutside(img);

			this.userPic.transition({
				x: cords.center,
				y: (this.y0 - cords.width),
				duration: 0
			});

			this._userPicBounceOutPicBorder(0);
		} catch(e) {
			this.tilezoomSettings.interaction = true;
			this.afterJoinIn();
			if(typeof console === 'object' && this.showErrors) { console.log('JOY.joyIn.Error',e); }
		}

		return this.userPic;
	};

	/************************** JOY IN ANIMATIONS **************************/


	this.placeUserPictureOutside = function(img) {
		var width = 200;
		if(width >= (this.containerSize.containerWidth-20)) {
			width = (this.containerSize.containerWidth-20);
		}

		if(width >= (this.containerSize.containerHeight-20)) {
			width = (this.containerSize.containerHeight-20);
		}

		var center = this.x0 + (this.containerSize.containerWidth / 2) - (width / 2);
		var top = 0;

		this.userPicSize = {
			width: width,
			height: width
		};

		var css = {
			width: this.userPicSize.width+'px',
			height: this.userPicSize.height+'px',
			left: '0px',
			top: '0px',
			backgroundImage: 'url('+img.src+')'
		};

		this.userPic.css(css);

		return {
			center: center,
			width: width
		};
	};


	this.placeOnTop = function () {

		var me = this;
		try {
			this.userPic.transition({
				rotate: '730deg',
				x: (this.tilePicDimension.left - (this.userPicSize.width/2) + (this.tilePicDimension.width/2))+'px',
				y: (this.tilePicDimension.top - (this.userPicSize.width/2)  + (this.tilePicDimension.height/2))+'px',
				complete: function() {
					me._userPicRotate(10,function() {
						me._userPicToTilePos();
					});
				}
			});
		} catch(e) {
			this.tilezoomSettings.interaction = true;
			this.afterJoinIn();
			if(typeof console === 'object' && this.showErrors) { console.log('JOY.placeOnTop.Error',e); }
		}

	};


	this._userPicRotate = function(r,c) {

		try {
			this.userPic.transition({
				rotate: r+'deg',
				complete: function() {
					if(typeof c === 'function') {
						c();
					}
				},
				duration: 300
			});
		} catch(e) {
			this.tilezoomSettings.interaction = true;
			this.afterJoinIn();
			if(typeof console === 'object' && this.showErrors) { console.log('JOY._userPicRotate.Error',e); }
		}

	};

	this._userPicToTilePos = function(d) {

		var me = this;

		try {
			var scale = (this.tilePicDimension.width/ this.userPicSize.width);
			if(!me.userPicBorder) {
				this.buildPlaceholder();
			}

			this.userPic.transition({
				scale: scale,
				rotate: '0deg',
				//border: 0,
				x: (this.tilePicDimension.left - ((this.userPicSize.width - this.tilePicDimension.width) / 2))+'px',
				y: (this.tilePicDimension.top  - ((this.userPicSize.width - this.tilePicDimension.width) / 2))+'px',
				complete: function() {
					me._userPicBounceOutPicBorder();
				},
				duration: d || 1000
			});
		} catch(e) {
			this.tilezoomSettings.interaction = true;
			this.afterJoinIn();
			if(typeof console === 'object' && this.showErrors) { console.log('JOY._userPicToTilePos.Error',e); }
		}

	};


	this._userPicBounceOutPicBorder = function(d) {

		var me = this;

		if(typeof d !== 'undefined' && d === 0) {
			me._showUserPicBorder();
			me._userPicScaleOutAgain(d);
		} else {

			if(me.userPicBorder && typeof me.userPic.addClass === 'function') {
				me.userPicBorder.addClass('animated').addClass('bounceOut');
			}

			UTIL.call_transitionEnd(me.userPicBorder,1000,function() {
				me._showUserPicBorder();
				me._userPicScaleOutAgain();
			});
		}


	};


	this._userPicScaleOutAgain = function(d) {

		try {
			var me = this;
			var newScale = 0.5;
			var resize = false;
			var edgeSpace = 30; // arrow width
			this.arrowWidth = 16;


			// check and guess alignment of big scaled user pic
			var right = {
				x: ((this.tilePicDimension.left + this.tilePicDimension.width) - (this.userPicSize.width * newScale) + (this.userPicSize.width * newScale / 2)) + this.arrowWidth,
				y: ((this.tilePicDimension.top + this.tilePicDimension.height) - (this.userPicSize.height * newScale)) - (this.tilePicDimension.height / 2)
			};
			var alignment = 'right';
			var pos = right;
			var maxRight = (this.x0 + this.containerSize.containerWidth) - (this.userPicSize.width * newScale) - edgeSpace;

			var left = {
				x: ((this.tilePicDimension.left) - (this.userPicSize.width * newScale) - (this.userPicSize.width * newScale / 2)) - this.arrowWidth,
				y: ((this.tilePicDimension.top + this.tilePicDimension.height) - (this.userPicSize.height * newScale)) - (this.tilePicDimension.height / 2)
			};
			var maxLeft = this.x0 + (this.userPicSize.width * newScale) - edgeSpace;

			var top = {
				x: ((this.tilePicDimension.left + this.tilePicDimension.width) - (this.userPicSize.width * newScale) - (this.tilePicDimension.width / 2)),
				y: ((this.tilePicDimension.top) - (this.userPicSize.height * newScale) - (this.userPicSize.height * newScale / 2)) - this.arrowWidth
			};
			var maxTop = this.y0 + (this.userPicSize.height * newScale) - edgeSpace;

			var bottom = {
				x: ((this.tilePicDimension.left + this.tilePicDimension.width) - (this.userPicSize.width * newScale) - (this.tilePicDimension.width / 2)),
				y: ((this.tilePicDimension.top + this.tilePicDimension.height) - (this.userPicSize.height * newScale) + (this.userPicSize.height * newScale / 2)) + this.arrowWidth
			};
			var maxBottom = (this.y0 + this.containerSize.containerHeight) - (this.userPicSize.height * newScale) - edgeSpace;

			var insideCornerDistance = this.arrowWidth -5;

			// left top corner
			if(top.y < maxTop && left.x < maxLeft) {
				pos.y = bottom.y -insideCornerDistance;
				pos.x = right.x -insideCornerDistance;
				alignment = 'inside-left-top';
			}
			else if(top.y < maxTop && right.x > maxRight) {
				pos.y = bottom.y -insideCornerDistance;
				pos.x = left.x -insideCornerDistance;
				alignment = 'inside-right-top';
			}
			else if(bottom.y > maxBottom && right.x > maxRight) {
				pos.y = top.y -insideCornerDistance;
				pos.x = left.x -insideCornerDistance;
				alignment = 'inside-right-bottom';
				resize = true;
			}
			else if(bottom.y > maxBottom && left.x < maxLeft) {
				pos.y = top.y -insideCornerDistance;
				pos.x = right.x -insideCornerDistance;
				alignment = 'inside-left-bottom';
				resize = true;
			}
			else if(bottom.y > maxBottom) {
				pos = top;
				alignment = 'top';
				resize = true;
			}
			else if(top.y < maxTop) {
				pos = bottom;
				alignment = 'bottom';
			}
			else if (left.x < maxLeft) {
				pos = right;
				alignment = 'right';
			}
			else if(right.x > maxRight) {
				pos = left;
				alignment = 'left';
			}

			if(d === 0) {
				this.userPic.css({
					scale: newScale,
					x: pos.x+'px',
					y: pos.y+'px'
				});
				me._userPicArrowBorder(pos,newScale,d,alignment,resize);
			} else {
				this.userPic.transition({
					scale: newScale,
					x: pos.x+'px',
					y: pos.y+'px',
					complete: function() {
						me._userPicArrowBorder(pos,newScale,d,alignment,resize);
					},
					duration: 500,
					easing: 'snap'
				});
			}


		} catch(e) {
			this.tilezoomSettings.interaction = true;
			this.afterJoinIn();
			if(typeof console === 'object' && this.showErrors) { console.log('JOY._userPicScaleOutAgain.Error',e); }
		}
	};

	this._userPicArrowBorder = function(pos,newScale,d,alignment,resize) {

		var me = this;

		try {


			var resetAllignment = function() {
				UTIL.colorize.insertRule('#user-pic-arrow-border:before','');
			};


			// pos alignment
			var useTransition = 'transform';
			var className = 'arrow-box-left';
			var userPicBorderSize = {
				w: ((this.userPicSize.width * newScale)-this.arrowWidth),
				h: ((this.userPicSize.height * newScale)),
				x: (pos.x + ((this.userPicSize.width * newScale /2 ) + this.arrowWidth)),
				y: (pos.y + ((this.userPicSize.width * newScale /2)))
			};

			var userPicBorderTransition = {
				x: pos.x + (this.userPicSize.width * newScale /2 ),
				y: pos.y + (this.userPicSize.width * newScale /2)
			};

			switch(alignment) {
				case'inside-left-top':
					className = 'arrow-box-inside-left-top';
					useTransition = 'scale';
					break;
				case'inside-left-bottom':
				case'inside-right-bottom':
				case'top':
					if(alignment !== 'top') {
						className = 'arrow-box-'+alignment;
						useTransition = 'scale';
						resize = true;
					} else {
						className = 'arrow-box-bottom';
					}
					userPicBorderSize.w = ((this.userPicSize.width * newScale));
					userPicBorderSize.h = ((this.userPicSize.height * newScale)-this.arrowWidth);
					userPicBorderSize.x = (pos.x + ((this.userPicSize.width * newScale /2 )));
					userPicBorderSize.y = (pos.y + ((this.userPicSize.width * newScale /2)));
					userPicBorderTransition.y = userPicBorderTransition.y + this.arrowWidth;
					break;
				case'bottom':
					className = 'arrow-box-top';
					userPicBorderSize.w = ((this.userPicSize.width * newScale));
					userPicBorderSize.h = ((this.userPicSize.height * newScale)-this.arrowWidth);
					userPicBorderSize.x = (pos.x + ((this.userPicSize.width * newScale /2 )));
					userPicBorderSize.y = (pos.y + ((this.userPicSize.width * newScale /2))+ this.arrowWidth);
					break;
				case'inside-right-top':
				case'left':
					if(alignment !== 'left') {
						className = 'arrow-box-inside-right-top';
						useTransition = 'scale';
					} else {
						className = 'arrow-box-right';
					}
					//userPicBorderSize.x = (pos.x + ((this.userPicSize.width * newScale /2 )));
					userPicBorderSize.x = (pos.x + ((this.userPicSize.width * newScale /2 )));
					userPicBorderSize.y = (pos.y + ((this.userPicSize.width * newScale /2)));
					userPicBorderTransition.x = userPicBorderTransition.x + this.arrowWidth;
					break;
			}


			if(!this.userPicArrowBorder) {
				this.$zoomTilesContainer.append('<div id="user-pic-arrow-border" class="'+className+'">');
			}
			this.userPicArrowBorder = $('#user-pic-arrow-border');
			this.userPicArrowBorder.removeClass();
			this.userPicArrowBorder.addClass(className);

			this.userPicArrowBorder.css({
				left: 0,
				top: 0,
				width: userPicBorderSize.w + 'px',
				height: userPicBorderSize.h + 'px',
				position: 'relative',
				x: userPicBorderSize.x +'px',
				y: userPicBorderSize.y +'px'
			});

			if(useTransition === 'transform') {
				this.userPicArrowBorder.transition({
					scale: 1,
					x: userPicBorderTransition.x + 'px',
					y: userPicBorderTransition.y +'px',
					complete: function() {
						if(resize) {
							setTimeout(function() {
								me.resizeTilezoom();
							},1000);
						}
						me.afterJoinIn();
					},
					duration: d || 300,
					easing: 'snap'
				});
			} else {
				this.userPicArrowBorder.css({
					scale: 1,
					x: userPicBorderTransition.x + 'px',
					y: userPicBorderTransition.y +'px'
				});
				this.userPicArrowBorder.transition({
					scale: 1.2,
					complete: function() {
						me.userPicArrowBorder.transition({
							scale: 1,
							complete: function() {
								if(resize) {
									setTimeout(function() {
										me.resizeTilezoom();
									},1000);
								}
								me.afterJoinIn();
							},
							duration: (d || 300)*3,
							easing: 'snap'
						});
					},
					duration: (d || 300)*3,
					easing: 'snap'
				});
			}


		} catch(e) {
			this.tilezoomSettings.interaction = true;
			this.afterJoinIn();
			if(typeof console === 'object' && this.showErrors) { console.log('JOY._userPicArrowBorder.Error',e); }

		}

	};


	this.resizeTilezoom = function() {

		var resize = false;

		// only on joyscreen
		var inJoyScreen = $('.app-mainstage.joy-screen');
		if(inJoyScreen && inJoyScreen.get(0)) {
			resize = true;
		}

		if(resize) {
			var me = this,
				tilezoomHeight = $(window).height() - 60;
			var tilezoom = $('.app-tilezoom-contain');
			tilezoom.css({
				height: tilezoomHeight
			});
			setTimeout(function () {
				me.tilezoomSettings.controllerSetSize();

				me.tilezoomSettings.transform = {
					x: me.tilezoomSettings.transform.x || 0,
					y: (me.tilezoomSettings.containerHeight - me.tilezoomSettings.holderHeight) || 0,
					scale: 1
				};
				UTIL.transit(me.tilezoomSettings.holder,me.tilezoomSettings.transform,{});

			}, 100);
		}

	};


	this._showUserPicBorder = function() {
		var me = this;

		try {

			if(!me.userPicBorder) {
				this.buildPlaceholder();
			}

			if(me.userPicBorder && typeof me.userPic.addClass === 'function') {
				me.userPicBorder.removeClass('animated');
			}

			me.userPicBorder.css({
				border: '2px solid #ffffff',
				backgroundColor: 'rgba(255,255,255,0)'
			});


		} catch(e) {
			if(typeof console === 'object' && this.showErrors) { console.log('JOY._showUserPicBorder.Error',e); }

		}

	};


	this.afterJoinIn = function() {
		var me = this;

		me.tilezoomSettings.interaction = true;


		if(typeof me.tilezoomSettings.afterJoyIn === 'function') {
			me.tilezoomSettings.afterJoyIn();
			me.tilezoomSettings.afterJoyIn = null;
		}
	};

	/************************** JOY OUT ANIMATIONS **************************/





	this._userPicScaleBig = function() {

		try {
			var me = this;
			var scale = (this.userPicSize.width / this.tilePicDimension.width);
			var borderWidth = 1;

			this.userPic.transition({
				scale: scale,
				rotate: '10deg',
				borderWidth: borderWidth + 'px',
				borderRadius: borderWidth+'px',
				x: (this.tilePicDimension.left + (this.tilePicDimension.width*2) - (this.userPicSize.width/2))+'px',
				y: (this.tilePicDimension.top  + (this.tilePicDimension.width*2) - (this.userPicSize.width/2))+'px',
				complete: function() {
					var route = UTIL.storage.getLocal('visibleContent');
					if(route === 'congratulation') {
						setTimeout(function() {me._userPicToMiniPic()},1000);
					} else {
						setTimeout(function() {me._userPicToTilezoomPosition(scale)},1000);
					}
				},
				duration: 350
			});
		} catch(e) {
			this.tilezoomSettings.interaction = true;
			if(typeof console === 'object' && this.showErrors) { console.log('JOY._userPicScaleBig.Error',e); }
		}

	};

	this._userPicToTilezoomPosition = function(scale) {
		try {
			var me = this;
			var borderWidth = 1;

			this.userPic.transition({
				scale: 1,
				rotate: '720deg',
				borderWidth: borderWidth + 'px',
				borderRadius:'1px',
				x: this.tilePicDimension.left+'px',
				y: this.tilePicDimension.top+'px',
				complete: function() {
					me.tilezoomSettings.interaction = true;
				},
				duration: 400,
				easing: 'cubic-bezier(0,0.9,0.3,1)'
			});
		} catch(e) {
			this.tilezoomSettings.interaction = true;
			if(typeof console === 'object' && this.showErrors) { console.log('JOY._userPicScaleBig.Error',e); }
		}
	};

	/**
	 * Move Userpicture to right tilezoom navigation
	 * @private
	 */
	this._userPicToMiniPic = function() {

		try {

			this.zoomToPhoto = $('.zoom-to-photo');
			var zTPPos = this.zoomToPhoto.offset();

			var XY = {
				x: this.x0 + zTPPos.left,
				y: this.y0 + zTPPos.top
			};

			var me = this;
			var scale = (this.zoomToPhoto.width()/ this.userPicSize.width);

			this.userPic.transition({
				scale: scale,
				rotate: '730deg',
				borderWidth: '1px',
				borderRadius:'1px',
				x: XY.x,
				y: XY.y,
				complete: function() {
					me.zoomToPhoto.addClass('animated tada');
					UTIL.call_transitionEnd(me.zoomToPhoto,1000,function() {
						me.zoomToPhoto.removeClass('animated').removeClass('tada');
					});
					me.tilezoomSettings.interaction = true;
					if(typeof me.tilezoomSettings.afterJoyIn === 'function') {
						UTIL.call_transitionEnd(me.userPicBorder,1000,function() {
							me.tilezoomSettings.afterJoyIn();
							me.tilezoomSettings.afterJoyIn = null;
						});
					}
					me.removeUserPicture();
				},
				duration: 500
			});
		} catch(e) {
			this.tilezoomSettings.interaction = true;
			if(typeof console === 'object' && this.showErrors) { console.log('JOY._userPicToMiniPic.Error',e); }
		}

	};


	/************************** SET USER PICTURE **************************/

	this.setUserPicture = function(img) {

		try {
			this.removeUserPicture();
			this.buildUserPic();

			this.userPic.css({
				width: this.tilePicDimension.width+'px',
				height: this.tilePicDimension.height+'px',
				x: this.tilePicDimension.left+'px',
				y: this.tilePicDimension.top+'px',
				left: '0px',
				top: '0px',
				backgroundImage: 'url('+img.src+')'
			});
		} catch(e) {
			this.tilezoomSettings.interaction = true;
			if(typeof console === 'object' && this.showErrors) { console.log('JOY.setUserPicture.Error',e); }
		}
	};

	/************************** TEST x0 and y0 coordinates **************************/


	this.test = function() {
		return true;
		this.$zoomTilesContainer.append('<div class="trcenter">');
		$('.trcenter').css({
			width: '10px',
			height: '10px',
			left: this.x0+'px',
			top: this.y0+'px',
			backgroundColor: 'red',
			position: 'absolute',
			zIndex: 10
		});
	};
}
