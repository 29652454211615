/**
 * TilezoommapWidget
 */

$.widget('ui.tilezoommap', {

	version: '1.8',
	options: {

		tilezoom:	null,
		thumb:		'',

		duration:	300,
		draggable:	true,
		clickable:	true,
		inAction: false,
		dragMomentum: true,
		transform: {
			x: 0,
			y: 0,
			scale: 1,
			origin: {
				x:0,
				y:0
			}
		},

		transformThumb: {
			x: 0,
			y: 0,
			scale: 1,
			origin: {
				x:0,
				y:0
			}
		},

		dragstop:	function ( x, y, zoomLevel ) {
			this.transform = {
				x:x,
				y:y
			};
		},
		click:		function ( x, y, zoomLevel ) {
			this.transform = {
				x:x,
				y:y
			};
		},
		load:		function ( $thumb ) { }
	},

	_create: function () {

		var me			= this,
			options		= me.options,
			$element	= me.element,
			tilezoom	= options.tilezoom = options.tilezoom.data('tilezoom.settings'),
			$wrapper	= me.thumbWrapper = $('<div>').addClass('ui-tilezoommap-wrapper');

		var $thumb = me.thumb = $('<img>', {
			src: options.thumb
		}).on('load', function () {
			options.load( $thumb );
		})
		.appendTo( $wrapper );

		var thumbDimensions =  options.thumbSize;
		me.setThumbDimensions(thumbDimensions.w,thumbDimensions.h);

		me.controlRectangle	= $('<div>').addClass('control-rectangle').appendTo( $wrapper );
		$wrapper.appendTo( $element );

		me.wrapper = $wrapper;

		if ( options.draggable ) {

			me.controlRectangle.css({
				position: 'absolute',
				top: 0,
				left: 0
			});

			me.initDraggable(tilezoom);
			me.initDraggableThumb(tilezoom);
		}

		// override tilezoom listener
		var superAfterZoom = tilezoom.afterZoom;
		tilezoom.afterZoom = function ($tilezoom, coords, zoomLevel) {

			if ( superAfterZoom ) {

				superAfterZoom(tilezoom, coords, zoomLevel);
			}

			me.doLayout(tilezoom, coords.x, coords.y, zoomLevel);
		};

		var superCallAfter = tilezoom.callAfter;
		tilezoom.callAfter = function ($tilezoom, coords, zoomLevel) {

			if ( superCallAfter ) {

				superCallAfter($tilezoom, coords, zoomLevel);
			}

			me.doLayout(tilezoom, coords.endX, coords.endY, zoomLevel);
		};

		var superAfterToggleFullScreen = tilezoom.afterToggleFullScreen;
		tilezoom.afterToggleFullScreen = function ( isFullScreen ) {

			if ( superAfterToggleFullScreen ) {

				superAfterToggleFullScreen( isFullScreen );
			}

			me.updateLayout( tilezoom );
		};

		if ( options.clickable ) {

			$thumb.click( $.proxy(me.onClick, me) );
		}

	},

	destroy: function () {
		var $element = this.element;
		this._super();
		$('.ui-tilezoommap-wrapper', $element).remove();
	},

	updateLayout: function ( tilezoom ) {

		var $holder	= tilezoom.holder;

		var x = tilezoom.transform.x * -1,
			y = tilezoom.transform.y * -1;

		this.doLayout(tilezoom, x, y, tilezoom.zoomLevel);
	},

	setThumbDimensions: function(w,h) {
		var	me = this;
		me.setThumbSize(w,h);

		var ww = $(window).width();
		var contentWrapper = me.element.closest('.app-content-wrapper');
		if(contentWrapper && contentWrapper.get(0)) {
			ww = contentWrapper.width();
		}

		me.thumb.css({
			width: me.options.thumbWidth,
			height: me.options.thumbHeight
		});

		me.thumbWrapper.css({
			width: me.options.thumbWidth,
			height: me.options.thumbHeight
		});
		me.element.css({
			height: me.options.thumbHeight,
			width: ww
		});

		me.setThumbWrapperSize(ww,h);

	},

	setThumbSize: function(w,h) {

		var	me					            = this,
			element				            = me.thumb;

		me.options.thumbWidth		    = w || element.width();
		me.options.thumbHeight		    = h || element.height();


		return {
			thumbWidth: me.options.thumbWidth,
			thumbHeight: me.options.thumbHeight
		}
	},

	setThumbWrapperSize: function(w,h) {

		var	me					            = this,
			element				            = me.thumbWrapper;

		me.options.thumbWrapperWidth		= w || element.width();
		me.options.thumbWrapperHeight		= h || element.height();

		return {
			thumbWrapperWidth: me.options.thumbWrapperWidth,
			thumbWrapperHeight: me.options.thumbWrapperHeight
		}
	},

	setElementSize: function() {

		var	me					            = this,
			element				            = me.element;

		// Todo if wind.width is greater than thumb width
		// set thumb width wo window width?

		me.options.elementWidth		    = element.width();
		me.options.elementHeight		= element.height();

		return {
			elementWidth: me.options.elementWidth,
			elementHeight: me.options.elementHeight
		}
	},

	getElementSize: function() {

		var	me					= this;

		return {
			elementWidth: me.options.elementWidth,
			elementHeight: me.options.elementHeight
		}
	},


	getRectangleSize: function() {},
	setRectangleSize: function(w,h) {

		var	me		    = this,
			$rectangle	= me.controlRectangle;

		me.options.rectangleWidth		= w || $rectangle.width();
		me.options.rectangleHeight		= h || $rectangle.height();

		return {
			rectangleWidth: me.options.rectangleWidth,
			rectangleHeight: me.options.rectangleHeight
		}

	},


	doLayout: function ( tilezoom, x, y, zoomLevel ) {

		var forceThumbUpdate = false;
		var	me					= this,
			element				= me.element;

		var updateProportions = true;
		if(updateProportions) {
			me.setThumbSize();
			me.setThumbWrapperSize();
			me.setElementSize();
			//me.setRectangleSize();
		}

		var	elementWidth		= me.options.elementWidth,
			elementHeight		= me.options.elementHeight,
			thumbWidth		    = me.options.thumbWidth,
			thumbHeight		    = me.options.thumbHeight,
			$thumbWrapper	    = me.thumbWrapper,
			$rectangle			= me.controlRectangle,
			tilezoomSettings    = me.options.tilezoom,
			container			= tilezoom.cont,
			containerWidth		= tilezoom.containerWidth || container.width(),
			containerHeight		= tilezoom.containerHeight || container.height(),
			holder				= tilezoom.holder,
			holderWidth			= tilezoom.holderWidth || holder.width(),
			holderHeight		= tilezoom.holderHeight || holder.height(),
			duration			= me.options.duration;

//		calculate width and height
		var rectangleWidth	= (containerWidth / holderWidth) * 100,
			rectangleHeight	= (containerHeight / holderHeight) * 100;

		rectangleWidth	= parseInt( parseFloat( rectangleWidth * thumbWidth / 100 ).toFixed(0) );
		rectangleHeight	= parseInt( parseFloat( rectangleHeight * thumbHeight / 100 ).toFixed(0) );

		var thumbLeft = parseInt( parseFloat( (x * thumbWidth / holderWidth) - (elementWidth / 2) + (rectangleWidth / 2)).toFixed(0) );
		var rectangleLeft	= parseInt( parseFloat( (x * thumbWidth / holderWidth) ).toFixed(0) );
		var rectangleTop	= parseInt( parseFloat( (y * thumbHeight / holderHeight) ).toFixed(0) );

		if ( rectangleLeft + rectangleWidth > thumbWidth ) {
			rectangleLeft = thumbWidth - rectangleWidth;
		}
		if ( rectangleTop + rectangleHeight > thumbHeight ) {
			rectangleTop = thumbHeight - rectangleHeight;
		}

		if ( rectangleWidth > thumbWidth ) {
			rectangleWidth = thumbWidth;
		}

		//if(typeof console === 'object') { console.log('doLayout.Pos3 rectangleLeft,rectangleTop,me._isInitialized',rectangleLeft,rectangleTop,me._isInitialized); }

		if ( rectangleTop < 0 ) {
			rectangleTop = 0;
		}
		if ( rectangleLeft < 0 ) {
			rectangleLeft = 0;
		}

		if ( rectangleHeight > thumbHeight ) {
			rectangleHeight = thumbHeight;
		}

		//if(typeof console === 'object') { console.log('doLayout x,y,rectangleLeft,rectangleTop,thumbLeft,me.options.transformThumb.x',x,y,rectangleLeft,rectangleTop,thumbLeft,me.options.transformThumb.x); }

		me.setRectangleSize(rectangleWidth,rectangleHeight);

		var wrap_checkThumbBoundaries = function(x) {
			me.options.transformThumb.x = -x;
			me.checkThumbBoundaries();
			//if(typeof console === 'object') { console.log('doLayout.wrap_checkThumbBoundaries me.options.transformThumb.x',me.options.transformThumb.x); }
			return me.options.transformThumb.x < 0 ? me.options.transformThumb.x : me.options.transformThumb.x * -1;
		};

		// check if rectangle is outer view
		if(me._isInitialized) {

			//if(typeof console === 'object') { console.log('Test IS OUTER VIEW elementWidth,(rectangleLeft-(rectangleWidth/2)),((me.options.transformThumb.x * -1) + elementWidth)',elementWidth,(rectangleLeft-(rectangleWidth/2)),((me.options.transformThumb.x * -1) + elementWidth)); }
			if((rectangleLeft+(rectangleWidth/2)) < (me.options.transformThumb.x * -1)) {
				forceThumbUpdate = true;
			} else if((rectangleLeft+(rectangleWidth/2)) > ((me.options.transformThumb.x * -1) + elementWidth)) {
				forceThumbUpdate = true;
			}
		}

		if((!me._isInitialized) || forceThumbUpdate === true) {
			var thumbPos = {
				x: wrap_checkThumbBoundaries(thumbLeft),
				y:0
			};
			UTIL.transit($thumbWrapper,thumbPos,{duration: duration});
			me.options.transformThumb = thumbPos;
		}

		me._isInitialized = true;

		$rectangle.css({
			width:	rectangleWidth +'px',
			height:	rectangleHeight +'px'
		});

		var recPos = {
			x:rectangleLeft,
			y:rectangleTop
		};
		UTIL.transit($rectangle,recPos,{duration: duration});
		me.options.transform = recPos;
	},



	onDragStop: function ( e, ui ) {

		var	me					= this,
			tilezoom			= me.options.tilezoom,
			$rectangle			= me.controlRectangle;


		var	rectangleWidth		= me.options.rectangleWidth || $rectangle.outerWidth(),
			rectangleHeight		= me.options.rectangleHeight || $rectangle.outerHeight();


		var	thumbWidth		    = me.options.thumbWidth,
			thumbHeight		    = me.options.thumbHeight,
			holder				= tilezoom.holder,
			container			= tilezoom.cont,
			containerWidth      = tilezoom.containerWidth || container.width(),
			containerHeight     = tilezoom.containerHeight || container.height(),
			holderWidth			= tilezoom.holderWidth || holder.width(),
			holderHeight		= tilezoom.holderHeight || holder.height();


		var	recLeft				= me.options.transform.x,//me.options.rectangleX,
			recTop				= me.options.transform.y;//me.options.rectangleY;

		var	percentX	= holderWidth / thumbWidth,
			percentY	= holderHeight / thumbHeight,
			x			= parseInt( parseFloat( ( recLeft + (rectangleWidth / 2) ) * percentX).toFixed(0) ),
			y			= parseInt( parseFloat( ( recTop + (rectangleHeight / 2) ) * percentY).toFixed(0) );



		if ( recLeft == 0 ) {
	
			x = parseInt( containerWidth / 2);
		}
		if ( recTop == 0 ) {
	
			y = parseInt( containerHeight / 2);
		}

		var tollerance		= 3,
			contWidthHalf	= containerWidth / 2,
			contHeightHalf	= containerHeight / 2;

		if ( ( tollerance + x + contWidthHalf) > holderWidth ) {
			x = parseInt( parseFloat( holderWidth - contWidthHalf ).toFixed(0) );
		}
		if ( ( tollerance + y + contHeightHalf) > holderHeight ) {
			y = parseInt( parseFloat( holderHeight - contHeightHalf ).toFixed(0) );
		}

		me.options.dragstop( x, y, tilezoom.level );
		container.tilezoom('moveTo', tilezoom.level, { left: x, top: y } );
	},

	onClick: function ( e ) {

		// noClick will be set on mousemove of overview image...
		if(this.options.noClick === true) {
			this.options.noClick = false;
			return false;
		}

		var	me				= this,
			roundFunc		= Math.round,
			tilezoom		= me.options.tilezoom,
			container		= tilezoom.cont,
			containerWidth      = tilezoom.containerWidth || container.width(),
			containerHeight     = tilezoom.containerHeight || container.height();


		var	thumbWidth		    = me.options.thumbWidth,
			thumbHeight		    = me.options.thumbHeight;


		var	holder			= tilezoom.holder,
			holderWidth	    = tilezoom.holderWidth || holder.width(),
			holderHeight	= tilezoom.holderHeight || holder.height(),

			percentX	= holderWidth / thumbWidth,
			percentY	= holderHeight / thumbHeight,
			x			= roundFunc( (e.clientX - $(e.target).offset().left) * percentX ),
			y			= roundFunc( (e.clientY - $(e.target).offset().top) * percentY );

		if ( me.options.click ) {

			me.options.click( x, y, tilezoom.level )
		}

		me.doLayout(tilezoom, roundFunc(x - containerWidth/2), roundFunc(y - containerHeight/2), tilezoom.level);
		container.tilezoom('moveTo', tilezoom.level, { left: x, top: y } );
	},


	/*
	 * Init Draggable funtionality
	 */
	initDraggable: function(tilezoom) {

		var isTouchSupported = (typeof(window.ontouchstart) != 'undefined');

		var	me				= this,
			roundFunc		= Math.round,
			tilezoom		= me.options.tilezoom,
			container		= tilezoom.cont,
			containerWidth      = tilezoom.containerWidth || container.width(),
			containerHeight     = tilezoom.containerHeight || container.height(),

			$rectangle		= me.controlRectangle;

		var $document		= isTouchSupported ? $rectangle : $(document),
			dragging	= false,
			startLeft	= 0,
			startTop	= 0;

		var startX = me.options.transform.x,
			startY = me.options.transform.y,
			pos	= {},
			runningMouseEvents = [],
			runningMouseEventsIDX = 0,
			minDistance = 40;


		var mouseDown =  function (e) {

			// stop momentum animation
			UTIL.stopMomentum('wall');
			UTIL.stopMomentum('wallRectangle');

			runningMouseEventsIDX=0;
			e.IDX = runningMouseEventsIDX;
			runningMouseEvents = [e];

			if(e.pointerType == 'touch') {
				e = e.pointers[0];
			} else {
				e = e.pageX ? e : e.originalEvent;
			}

			if (me.options.inAction) {
				return false;
			}

			dragging = true;

			startLeft	= me.options.transform.x;
			startTop	= me.options.transform.y;

			startX = e.pageX;
			startY = e.pageY;
			//if(typeof console == 'object') console.log('mouseDown startLeft,startTop,startX,startY',startLeft,startTop,startX,startY);

			if(!isTouchSupported) {
				$document.on('mousemove', mouseMove);
				$document.one('mouseup', mouseUp);
			}


			return false;
		};

		var mouseMove = function (e) {

			if (e.timeStamp - runningMouseEvents[runningMouseEvents.length-1].timeStamp > 40) {
				runningMouseEventsIDX++;
				e.IDX = runningMouseEventsIDX;
				runningMouseEvents.push(e);
				if (runningMouseEvents.length > 2) {
					runningMouseEvents.shift();
				}
			}

			if(e.pointerType == 'touch') {
				e = e.pointers[0];
			} else {
				e = e.pageX ? e : e.originalEvent;
			}

			me.options.inAction = true;

			if ( dragging ) {

				var offsetX =  e.pageX - startX,
					offsetY =  e.pageY - startY;

				me.options.transform.x	= startLeft + offsetX;
				me.options.transform.y	= startTop + offsetY;

				UTIL.transform($rectangle,me.options.transform);

			}
		};

		var mouseUp = function (e) {
			me.options.inAction = false;
			if(!isTouchSupported) {
				$document.off("mousemove touchmove");
			}

			$rectangle.removeClass('in-action');

			if(me.options.dragMomentum) {
				UTIL.momentum(e,$rectangle,'wallRectangle',me.options.transform,{
					runningMouseEvents:runningMouseEvents,
					endCallback:endMouseUp,
					checkBoundaries: function() {}
				});
			} else {
				me.onDragStop(e);
			}

		};

		var endMouseUp = function(e) {
			dragging = false;
			me.onDragStop(e);
		};


		if(isTouchSupported) {

			var inPinch = false;

			var mc = new Hammer.Manager($rectangle.get(0));
			mc.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
			mc.add(new Hammer.Tap({ event: 'tap' }));

			mc.on('tap',function() {
				UTIL.stopMomentum('wall');
				UTIL.stopMomentum('wallRectangle');
			});

			mc.on("panstart panend panmove", function(e) {

				if(!$(e.target).hasClass('control-rectangle')) {
					return false;
				}

				// do pinching without panning...
				if(inPinch) {
					return false;
				}

				switch(e.type) {
					case'panstart':
						mouseDown(e);
						break;
					case 'panend':
						mouseUp(e);
						break;
					case 'panmove':
						mouseMove(e);
				}

				e.preventDefault();
			});

			mc.on("hammer.input", function(e) {
				if(e.isFinal) {
					me.options.inAction = false;
				}
			});
		} else {
			$rectangle.on('mousedown', mouseDown);
		}
	},

	/*
	 * Init Draggable funtionality
	 */
	initDraggableThumb: function(tilezoom) {

		var isTouchSupported = (typeof(window.ontouchstart) != 'undefined');

		var	me				= this,
			roundFunc		= Math.round,
			tilezoom		= me.options.tilezoom,
			container		= tilezoom.cont,

			$thumbWrapper	= me.thumbWrapper;

		var $document		= isTouchSupported ? $thumbWrapper : $(document),
			dragging	= false,
			startLeft	= 0,
			startTop	= 0;

		var startX = me.options.transformThumb.x,
			startY = me.options.transformThumb.y,
			pos	= {},
			runningMouseEvents = [],
			runningMouseEventsIDX = 0,
			minDistance = 40;


		var mouseDown =  function (e) {

			UTIL.stopMomentum('wall');
			UTIL.stopMomentum('wallRectangle');

			runningMouseEventsIDX=0;
			e.IDX = runningMouseEventsIDX;
			runningMouseEvents = [e];

			if(e.pointerType == 'touch') {
				e = e.pointers[0];
			} else {
				e = e.pageX ? e : e.originalEvent;
			}

			if (me.options.inAction) {
				return false;
			}

			dragging = true;

			startLeft	= me.options.transformThumb.x;
			startTop	= me.options.transformThumb.y;

			startX = e.pageX;
			startY = e.pageY;
			$thumbWrapper.addClass('in-action');

			if(!isTouchSupported) {
				$document.on('mousemove', mouseMove);
				$document.one('mouseup', mouseUp);
			}

			return false;
		};

		var mouseMove = function (e) {

			if (e.timeStamp - runningMouseEvents[runningMouseEvents.length-1].timeStamp > 40) {
				runningMouseEventsIDX++;
				e.IDX = runningMouseEventsIDX;
				runningMouseEvents.push(e);
				if (runningMouseEvents.length > 2) {
					runningMouseEvents.shift();
				}
			}

			if(e.pointerType == 'touch') {
				e = e.pointers[0];
			} else {
				e = e.pageX ? e : e.originalEvent;
			}

			me.options.inAction = true;
			me.options.noClick = true;


			if ( dragging ) {

				var offsetX =  e.pageX - startX,
					offsetY =  e.pageY - startY;

				me.options.transformThumb.x	= startLeft + offsetX;
				me.options.transformThumb.y	= 0;

				me.checkThumbBoundaries();

				UTIL.transform($thumbWrapper,me.options.transformThumb);


			}
		};

		var mouseUp = function (e) {
			me.options.inAction = false;

			if(!isTouchSupported) {
				$document.off("mousemove touchmove");
			}

			$thumbWrapper.removeClass('in-action');

			if(me.options.dragMomentum) {
				UTIL.momentum(e,$thumbWrapper,'wall',me.options.transformThumb,{
					runningMouseEvents:runningMouseEvents,
					endCallback:endMouseUp,
					checkBoundaries: function() {
						me.checkThumbBoundaries();
					}
				});
			} else {
				endMouseUp(e);
			}

		};

		var endMouseUp = function(e) {
			dragging = false;
		};


		if(isTouchSupported) {

			var inPinch = false;

			var mc = new Hammer.Manager($thumbWrapper.get(0));
			mc.add(new Hammer.Pan({ threshold: 0, pointers: 0, enable: true }));
			mc.add(new Hammer.Tap({ event: 'tap' }));

			mc.on('tap',function() {
				UTIL.stopMomentum('wall');
				UTIL.stopMomentum('wallRectangle');
			});

			mc.on("panstart panend panmove", function(e) {

				if($(e.target).hasClass('control-rectangle')) {
					return false;
				}

				// do pinching without panning...
				if(inPinch) {
					return false;
				}

				switch(e.type) {
					case'panstart':
						mouseDown(e);
						break;
					case 'panend':
						mouseUp(e);
						break;
					case 'panmove':
						mouseMove(e);
				}

				e.preventDefault();

			});

			mc.on("hammer.input", function(e) {
				if(e.isFinal) {
					me.options.inAction = false;
				}
			});
		} else {
			$thumbWrapper.on('mousedown', mouseDown);
		}

	},

	checkThumbBoundaries: function() {
		if(this.options.elementWidth > this.options.thumbWrapperWidth) {
			this.options.transformThumb.x = 0;
		}
		else if(this.options.transformThumb.x <= (this.options.elementWidth - this.options.thumbWrapperWidth)) {
			this.options.transformThumb.x = (this.options.elementWidth - this.options.thumbWrapperWidth);
		}
		else if(this.options.transformThumb.x >= 0) {
			this.options.transformThumb.x = 0;
		}

		this.options.transformThumb.y = 0;

	}

});