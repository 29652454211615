/**
 * Click on Tile to display photo and photo informations
 */
function tilezoomPhoto() {

	this.isVisible = false;

	this.ease_Visible = 'cubic-bezier(.33,.06,.48,.8)'; // 'cubic-bezier(0,0.9,0.3,1)'
	this.ease_Hidden = 'cubic-bezier(.33,.06,.48,.8)'; // 'cubic-bezier(.31,.08,.9,.42)'
	this.dur_MoveOutside = 600;
	this.dur_MoveInside = 600;

	/************************** INITIALIZATION **************************/

	this.init = function($cont) {
		this.tilezoom = $cont;
		this.tilezoomSettings = $cont.data('tilezoom.settings');
		this.$zoomTilesContainer = $('.zoom-tiles');
		this.zoomActionContainer = $cont.find('.zoom-action');
		//this.$container = $('.zoom-container');
		this.$container = $('.app-wrapper');
	};

	/************************** Tilezoom FUNCTIONS **************************/

	this.getHolderSize = function($cont) {
		return this.tilezoomSettings.cont.tilezoom('getHolderSize', $cont);
	};

	this.getContainerSize = function($cont) {
		return this.tilezoomSettings.cont.tilezoom('getContainerSize', $cont);
	};

	this.getScale = function(level) {
		return this.tilezoomSettings.cont.tilezoom('getScale', level);
	};

	this.updateViewportSize = function() {
		this.holderSize      = this.getHolderSize(this.tilezoom);
		this.containerSize   = this.getContainerSize(this.tilezoom);
		this.tilezoomSettings.util.getViewportCoordinates(this);
	};

	/************************** Picture FUNCTIONS **************************/


	this.getPhoto = function(e) {

		var me = this;
		var target = this.tilezoomSettings.currentTarget;

		if(!e) {
			return false;
		} else if(e.custom) {
			this.coordinates = e.coordinates;
			if(e.target) {
				target = e.target;
			}
		} else {
			this.coordinates = this.tilezoomSettings.util.getClickCoordinates(e);
		}

		this.updateViewportSize();
		this.buildOverlay();
		me.appendOverlayStructure();

		if(!this.isVisible) {
			//this.tilezoomSettings.util._placeLoaderOnTile(this.coordinates);
			me.setOverlayPositionOutside();
		} else {
			this.removePicture();
		}

		var l_url = '/whois/'+target+'/'+this.coordinates.x+'/'+this.coordinates.y;
		$.get(l_url, {}).then(function(data) {
			//if(typeof console === 'object') { console.log('getPhoto.data',data); }
			if(typeof data === 'object' && typeof data.whois === 'object') {
				me.tilezoom.tilezoom('stopScreensaver',e);
				me.getData(data);
				me.overlayEvents(data);

				if(!me.isVisible) {
					fastdom.defer(18,function() {
						me.setOverlayPosition();
					});
				}

				me.isVisible = true;
				me.tilezoomSettings.util.loadImage(me,data.whois.pic.id,function(a,b,c,d,img) {
					//me.$container.append('<div id="'+data.whois.pic.id+'" class="zoom-picture zo-picture-large sb cover">');
					//data.whois.pic.$pic = $('#'+data.whois.pic.id);
					var width = me.tilezoomSettings.util.getPictureSize(me);
					data.whois.pic.size = {
						width: width,
						height: width
					};
					data.whois.pic.src = img.src;
					me.placeImage(data.whois.pic);
				},data.whois);

			} else {
				me.removeOverlay();
			}
		}, function(a) {
			if(typeof console === 'object') { console.log('ERROR ! PICTURES?',a); }
			me.removeOverlay();
		}.bind(this));
	};

	/************************** Picture FUNCTIONS **************************/

	this.placeImage = function(picture) {
		$('.zo-pic').css({
			backgroundImage: 'url('+picture.src+')'
		});

		$('.zo-pic .loading-animation').remove();
	};

	/************************** Overlay FUNCTIONS **************************/


	this.buildOverlay = function() {

		var me = this;
		var $zoomOverlay = $('.zoom-overlay');

		if($zoomOverlay && $zoomOverlay.get(0)) {
			me.$zoomOverlay = $zoomOverlay;
		} else {
			me.$container.append('<div class="zoom-overlay wow-modal"></div>');
			me.$zoomOverlay = $('.zoom-overlay');

			me.draggable = new UTIL.draggable();
			me.draggable.init(me.$zoomOverlay,$('body'),{});
		}
	};

	this.appendOverlayLoader = function() {
		var me = this;
		me.$zoomOverlay.html('<div class="loading-animation"><i class="icon-spin4 animate-spin"></i></div>');
	};

	this.appendOverlayStructure = function() {

		var me = this;
		var zoPic = $('.zo-pic');
		var html = '';

		if(zoPic && zoPic.get(0)) {
			zoPic.html(this.tilezoomSettings.util.getLoadingAnimation());
		} else {
			html += '<div class="zoom-overlay-container">';
			html += '<button type="button" class="close zo-close">×</button>';
			html += '<div class="zo-pic cover">';
			html += this.tilezoomSettings.util.getLoadingAnimation();
			html += '</div>';
			html += '<div class="zo-data"></div>';
			html += '</div>';
			html += '';
			html += '';
			me.$zoomOverlay.html(html);
		}
	};

	this.removeOverlay = function() {
		var me = this;
		this.removePicture();
		if(me.$zoomOverlay) {
			this.setOverlayPositionOutside();
		}
		this.isVisible = false;
	};

	this.removePicture = function() {
		var zoPic = $('.zo-pic');
		if(zoPic && zoPic.get(0)) {
			zoPic.css({
				backgroundImage: 'none'
			});
		}
	};

	this.getCenterPosition = function() {
		return ($(window).width() / 2) - (this.$zoomOverlay.width() / 2);
	};

	this.setOverlayPositionOutside = function(o) {
		var me = this;
		o = o || {};

		fastdom.read(function() {
			me.draggable.transform.x = me.getCenterPosition();
			me.draggable.transform.y = (me.$zoomOverlay.height() + 400) * -1;
			fastdom.write(function() {
				UTIL.transit(me.$zoomOverlay,me.draggable.transform,{
					duration: me.dur_MoveOutside,
					callback: function() {
						me.$zoomOverlay.css({visibility:'visible'})
					},
					easing: me.ease_Hidden
				});
			});
		});
	};

	this.setOverlayPosition = function() {

		var me = this;

		try {
			fastdom.read(function() {
				me.draggable.transform.x = me.getCenterPosition();
				me.draggable.transform.y = 20;

				fastdom.write(function() {
					UTIL.transit(me.$zoomOverlay,me.draggable.transform,{duration: me.dur_MoveInside,easing:me.ease_Visible});
				});
			});
		} catch(e) {
			if(typeof console === 'object' && this.showErrors) { console.log('SCREENSAVER._userPicScaleBig.Error',e); }
		}

	};

	this.getData = function(data) {

		var $zoData = $('.zo-data'),
			t = '';

		//uploadDate
		var created = null;
		if(data.whois && data.whois.pic && data.whois.pic.uploadDate) {
			var date = data.whois.pic.uploadDate;
			created = $.format.date(date, "dd.MM.yyyy");
		}

		var motto = null;
		if(data.whois && data.whois.pic && data.whois.pic.motto) {
			motto = data.whois.pic.motto;
		}

		var city = null;
		if(data.whois && data.whois.pic && data.whois.pic.city) {
			city = data.whois.pic.city;
		}

		var country = null;
		if(data.whois && data.whois.pic && data.whois.pic.countryName) {
			country = data.whois.pic.countryName;
		}

		this.ping = null;
		if(this.tilezoomSettings.util.userIsLoggedIn() && data.whois && data.whois.profile && data.whois.profile.connect) {
			if(data.whois.pinged) {
				this.ping = data.whois.pinged || 'pinged!';
			} else {
				this.ping = true;
			}
		}

		var showData = {
			motto: {
				label: null,
				value: motto
			},
			date: {
				label: this.tilezoomSettings.controller.t( 'my_photo.uploaded'),
				value: created
			},
			city: {
				label: this.tilezoomSettings.controller.t( 'my_photo.city'),
				value: city
			},
			country: {
				label: this.tilezoomSettings.controller.t( 'my_photo.country'),
				value: country
			}
		};

		//for(var i in showData) {
		//	if(!showData[i].value) continue;
		//	t += '<li class="'+i+'">';
		//	t += '<div class="pull-right">'+showData[i].value+'</div> ';
		//	if(showData[i].label) {
		//		t += showData[i].label;
		//	} else {
		//		t += '<div class="clearfix"></div>'
		//	}
		//	t += '</li>';
		//}
		//
		//
		//if(data && data.admin) {
		//	t += '<li class="admin">';
		//	if(data.admin['edit-pic']) {
		//		t += '<div class="pull-right"><a href="'+data.admin['edit-pic']+'" target="_blank"><i class="icon-picture"></i></a></div> ';
		//	}
		//	if(data.admin['edit-user']) {
		//		t += '<div class="pull-right"><a href="'+data.admin['edit-user']+'" target="_blank"><i class="icon-user_square"></i></a></div> ';
		//	}
		//	t += this.tilezoomSettings.controller.t( 'buttons.edit');
		//	t += '</li>';
		//	//user_square
		//}
		//
		//t = '<div class="import-list"><ul>'+t+'</ul></div>';

		var hasDataIdx=0;
		for(var i in showData) {
			if(!showData[i].value) continue;
			t += '<span class="'+i+'">';

			if(i !== 'motto' && hasDataIdx > 0) {
				t += ', ';
			}

			t += showData[i].value;
			t += '</span>';
			if(i !== 'motto') {
				hasDataIdx++;
			}
		}


		if(data && data.admin) {
			t += '<span class="admin">';
			if(hasDataIdx > 0) {
				t += ', ';
			}
			t += this.tilezoomSettings.controller.t( 'buttons.edit')+': ';
			if(data.admin['edit-pic']) {
				t += '<a href="'+data.admin['edit-pic']+'" target="_blank"><i class="icon-picture"></i></a> ';
			}
			if(data.admin['edit-user']) {
				t += '<a href="'+data.admin['edit-user']+'" target="_blank"><i class="icon-user_square"></i></a> ';
			}
			t += '</span>';
		}

		t = '<div class="zo-data-content">'+t+'</div>';

		// ping
		var pClass = '';
		var p = '';
		t += '<div class="modal-footer">';

		if(this.ping) {
			pClass = ' col2';
			p = '<li>';
			p += '<a href="#" class="btn btn-wow zo-ping">';
			p += '<i class="hidden icon-spin4 animate-spin"></i>';
			p += this.tilezoomSettings.controller.t( 'my_photo.ping');
			if(this.ping === true) {
				p += '<i class="hidden icon-ok"></i>';
			} else {
				p += '<i class="icon-ok"></i>';
			}
			p += '</a>';
			p += '</li>';
		}

		t += '<ul class="btn-group buttons-horizontal'+pClass+'">';
		t += p;
		t += '<li>';
		t += '<a href="#" class="btn btn-wow zo-close">'+this.tilezoomSettings.controller.t( 'buttons.close')+'</a>';
		t += '</li>';
		t += '</ul>';
		t += '</div>';


		$zoData.html(t);

	};

	this.overlayEvents = function(data) {
		var me = this;

		$('.zo-close').off().on('click',function(e) {
			me.removeOverlay();
			e.preventDefault();
		});

		$('.zo-ping').off().on('click',function(e) {
			me.do_ping(data);
			e.preventDefault();
		});
	};

	this.do_ping = function(data) {
		//if(typeof console === 'object') { console.log('PING!',data); }
		var l_url;
		var $zoPiing = $('.zo-ping');
		var disabled = $zoPiing.attr('disabled');
		var me = this;

		if(disabled) {
			return false;
		}

		var post_data = {};
		$zoPiing.find('i.icon-spin4').removeClass('hidden');
		$zoPiing.attr('disabled','disabled');

		var controller = this.tilezoomSettings.controller;
		var store = controller.store;
		var application = controller.get('controllers.application');

		if(this.ping === true) {
			post_data = {targetPic:data.whois.pic.id};
			var newPing = store.createRecord('interaction', post_data);
			newPing.save().then(function(data) {
				if(typeof data === 'object' && data.get('sender.id')) {
					me.ping = data.get('id');

					var numSentInt = application.get('user.numSentInt');
					application.set('user.numSentInt',(numSentInt+1));

					store.findQuery('interaction',{sender:data.get('sender.id')}).then(function(records) {
						var myWOWs = controller.get('controllers.my-wows');
						myWOWs.set('model',records);
						return records;
					});
				}

				$zoPiing.find('i.icon-spin4').addClass('hidden');
				$zoPiing.find('i.icon-ok').removeClass('hidden');
				$zoPiing.removeAttr('disabled');
			});
		} else {
			$zoPiing.find('i.icon-ok').addClass('hidden');
			l_url = '/api/v1/interaction';
			post_data = {id:this.ping};
			store.find('interaction', this.ping).then(function (interaction) {
				if(typeof console === 'object') { console.log('DELETE',interaction); }
				interaction.deleteRecord();
				interaction.get('isDeleted');
				interaction.save();

				var numSentInt = application.get('user.numSentInt');
				application.set('user.numSentInt',(numSentInt-1));

				me.ping = true;
				$zoPiing.find('i.icon-spin4').addClass('hidden');
				$zoPiing.find('i.icon-ok').addClass('hidden');
				$zoPiing.removeAttr('disabled');
			});
		}
	};


	/************************** Events **************************/

	this.initEvents = function() {

		// bullshit tilezoom needs to control this!
		return;

		var me = this;
		var isTouchSupported = (typeof(window.ontouchstart) != 'undefined');
		var $zoomAction = this.tilezoom.find('.zoom-action');
		var $map = $('.ui-tilezoommap-wrapper');
		var $body = $('body');

		// onresize?
		var mouseDown = function(e) {
			if(typeof console === 'object') { console.log('MOUSEDOWN',e,$(this)); }

			var _this = $(this);
			var n = _this.closest('.zoom-navigation');
			var r = _this.closest('.zoom-navigation').get(0);//(n && n.get(0));

			if(r) {
				return;
			}

			$cont.tilezoom('stopScreensaver',e);
			me.getPhoto(e);
		};

		// @zoom-action only onmove? mousemeve and pan
		// add stopScreensaver to all other pans, touches and clicks to stop it emideatly ('zoom-action')
		var eventSelectors = ['zoom-action'];


		if(isTouchSupported) {
			for(var i=0; i < eventSelectors.length;i++) {
				var ev = $('.'+eventSelectors[i]);
				if(!ev && ev.get(0)) continue;
				if(typeof console === 'object') { console.log('ev',ev); }
				if(this.events[ev]) {
					this.events[ev].off('tap',mouseDown);
				}
				this.events[eventSelectors[i]] = new Hammer.Manager(ev.get(0));
				this.events[eventSelectors[i]].add(new Hammer.Tap({ event: 'tap'}));
				this.events[eventSelectors[i]].on('tap',mouseDown);
			}
		} else {
			for(var i=0; i < eventSelectors.length;i++) {
				$('.'+eventSelectors[i]).off('mousedown', mouseDown).on('mousedown', mouseDown);
			}
		}
	};





























	this._placeImageOnOverlay = function (picture,r,a) {

		if(typeof console === 'object') { console.log('_placeImageOnOverlay',picture); }

		var me = this;

		try {
			var scale = 1;
			var $picWrapper = $('.zo-pic');
			var xy = $picWrapper.offset();

			var x = xy.left;
			var y = xy.top - (picture.size.width/3);
			r = (r*-1);

			if((y+picture.size.width) > this.$container.height()) {
				y = this.$container.height() - 10 - picture.size.width;
			}

			if(typeof console === 'object') { console.log('$picWrapper.x,y,$picWrapper,xy,r',x,y,$picWrapper,xy,r,this.$container.height()); }

			//var x = (picture.tilePicDimension.left - (picture.tilePicDimension.width/2));
			//var y = (picture.tilePicDimension.top - (picture.tilePicDimension.width/2));
			//var offset = picture.size.width / 10;
			//
			//// check boundaries
			//if((x+offset) < this.scaledViewport.x) {
			//	x = this.scaledViewport.x - offset;
			//}
			//if((y+offset) < this.scaledViewport.y) {
			//	y = this.scaledViewport.y - offset;
			//}
			//if((x + picture.size.width) > (this.scaledViewport.x + this.scaledViewport.width + offset)) {
			//	x = this.scaledViewport.x + this.scaledViewport.width - picture.size.width + offset;
			//}
			//
			//if((y + picture.size.width) > (this.scaledViewport.y + this.scaledViewport.height + offset)) {
			//	y = this.scaledViewport.y + this.scaledViewport.height - picture.size.width + offset;
			//}



			//if(typeof console === 'object') { console.log('_userPicScaleBig x,y,this.scaledViewport,picture.size',x,y,this.scaledViewport,picture.size,picture.tilePicDimension); }
			//if(typeof console === 'object') { console.log('_userPicScaleBig rotate',r); }
			fastdom.write(function() {
				picture.$pic.transition({
					scale: scale,
					rotate: r + 'deg',
					x: x+'px',
					y: y+'px',
					complete: function() {


					},
					easing: 'cubic-bezier(.31,.08,.9,.42)',
					duration: 1500
				});
			});

		} catch(e) {
			//this.tilezoomSettings.interaction = true;
			if(typeof console === 'object' && this.showErrors) { console.log('PHOTO._placeImageOnOverlay.Error',e); }
		}
	};






	this.showOverlay = function() {

		return;
		var me = this;
		//var $zoomOverlay = $('.zoom-overlay');

		this.removeOverlay();

		fastdom.write(function() {
			if(!me.$zoomOverlay) {
				me.$zoomTilesContainer.append('<div class="zoom-overlay"></div>');
			}

			fastdom.read(function() {
				if(!me.$zoomOverlay) {
					me.$zoomOverlay = $('.zoom-overlay');
				}

				fastdom.write(function() {
					$zoomOverlay.append('<div class="zoom-overlay-container"><div class="zo-pic"></div><div class="zo-data"></div></div>');
					fastdom.write(function() {
						me.setOverlayPositionOutside({bottom:-1000});
						//me.getData(data);
						me.setOverlayPosition();
					});
				});
			});
		});
	};



	this.getCoordinates = function(e) {


		if(e.pointerType == 'touch') {
			e = e.pointers[0];
		} else {
			e = e.pageX ? e : e.originalEvent;
		}

		if(typeof console === 'object') { console.log('CLICKED on TILE getCoordinates',e,this.tilezoomSettings); }

		var startLeft	= this.tilezoomSettings.transform.x * -1,
			startTop	= this.tilezoomSettings.transform.y * -1;

		var startX      = e.pageX,
			startY      = e.pageY;

		this.scale =  this.getScale (this.tilezoomSettings.level, this.tilezoomSettings);

		//if(typeof console === 'object') { console.log('startX,startY,offsetX,offsetY',startX,startY,(startLeft + startX),(startTop + startY),this.scale,(startTop + startY) * this.scale); }


		this.coordinates = {
			x: (startLeft + startX) / this.scale,
			y: (startTop + startY) / this.scale
		};


		//if(typeof console === 'object') { console.log('this.coordinates',this.coordinates); }

	};
}
